import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ApiService } from '../../shared/api.service';
import { ConfirmationService, SharedModule, ConfirmDialogModule, InplaceDisplay } from 'primeng/primeng';
import { Router, ActivatedRoute } from '@angular/router';
import { NbToastrService, NbDialogService } from '@nebular/theme';
import { ShowStepperforApp } from '../dashboard/ShowStepperforApp';
import { applndetails } from './applndetails';
import { AddAffiliationInstructionaldetails } from './AddInstitutionAffiliationDetails';
import { transcriptpreview } from '../admin-dashboard/dialog/transcriptpreview.component';
import { Addinstitutiondialog } from '../student/new-attestation/dialog/Addinstitutiondialog';
import { ClassGetter } from '@angular/compiler/src/output/output_ast';
import { config } from '../../../../config';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { UploadDocuments } from './uploadDocuments.component';
import { FormGroup, FormBuilder, FormArray, Validators, FormControl } from '@angular/forms';
import { changeClgNameComponent } from './changeClgName.component';
import { ConfirmationDialogComponent } from '../../Confirmation-Dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material';
import { AddWes_Form } from '../student/new-attestation/dialog/WesForm';
@Component({
  selector: 'ngx-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
  providers: [ConfirmationService],
})

export class ViewComponent implements OnInit {
  // hrd array
  HrdEditvalue: any;
  HrdForm: FormGroup;
  hrdformArray: FormArray


  // affiliation array
  AffiliationEditValue: any;
  AffiliationForm: FormGroup;
  affiliationarray: FormArray;


  // instructional array
  InstrcutionalEditValue: any;
  InstructionalForm: FormGroup;
  instructionalarray: FormArray;


  // letter for namechange
  letterforNameChangeForm: FormGroup;
  letterfornamechangearray: FormArray;
  LetterfornameChangeEditValue: any;

  //Queue
  processedValue;
  process = [];


  LetterforNameChangeform:FormGroup;
  formEnable : boolean; 
  namechangeletterdetails;

  [x: string]: any;
  profile: any;
  my_app_data: any;
  userId: string;
  position: string;
  TranscriptData: any = [];
  MarkListData: any = [];
  CurriculumData: any = [];
  instituteData: any = [];
  instructionalDetails: Object;
  stuname: any;
  stucollege: any;
  status: string;
  stucourse: any;
  stuspec: any;
  studiv: any;
  studur: any;
  stuyear: any;
  app_id: string;
  viewFrom: string;
  letters: any;
  ExtraDocument: any;
  showOptions: boolean = false;
  tbpurpose: boolean;
  text: string;
  otherpurpose: any;
  display: boolean = false;
  created_at: any;
  updated_at: any;
  display_first: boolean = false;
  displayPurpose: boolean = false;
  appliedDetailsChange: boolean = false;
  degreeDetailsChange: boolean = false;
  stuname1: any;
  stucourse1: any;
  studiv1: any;
  stucollege1: any;
  stuspec1: any;
  studur1: any;
  serverUrl = config.serverUrl;
  currentLocation: boolean = false;
  marksheetApproved: boolean = false;
  transcriptApproved: boolean = false;
  convocationApproved: boolean = false;
  NameChangeLetter: any;
  instructionalData = [];
  WesFormData= [];
  LetterForNameChange = [];
  hrdData = [];
  affiliationData = [];
  applied = []
  demodata: any;
  demodata1: any;
  studentEmail: string;

  educationalChecked: boolean = false;
  affiliationChecked: boolean = false;
  curriculumChecked: boolean = false;
  instructionalChecked: boolean = false;
  letterforNameChangeChecked: boolean = false;
  gradToPerChecked: boolean = false;
  competencyLetterChecked: boolean = false;

  candidateType: boolean = false;
  addNote: boolean = false;
  notesType: string = '';
  notes: String = "";
  notes_area: string;

  instructionalEnable: boolean;
  viewInstructionalDetails: any;
  instu_id: any;
  instu_college: any;
  instu_course: any;
  instu_name: any;
  instu_spec: any;
  instu_div: any;
  instu_dur: any;
  instu_year: any;
  sameCollege: boolean;
  academicYear: any;
  academicYearAffiliation: any;
  yearsArray: any;

  NameChangeId : number;
  app_id_namechange : number;
  instructionalId : number;
  affiliationId : number;
  constructor(
    private _location: Location,
    protected api: ApiService,
    private route: ActivatedRoute,
    public dialogService: NbDialogService,
    private toastrService: NbToastrService,
    private confirmationService: ConfirmationService,
    private authService: NbAuthService,
    private fb: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
  ) {
    this.viewFrom = this.route.snapshot.queryParamMap.get('viewFrom');
  }

  ngOnInit() {
    // instructional 
    this.instructionalForm = this.fb.group({
      idCtrl: [], // id control with required validator
      nameCtrl: ['', Validators.required], // Name control with required validator
      courseCtrl: ['', Validators.required], // Course control with required validator
      acadYearCtrl: [''], // Academic year control with required validator
      collegeCtrl: ['', Validators.required], // College control with required validator
      specializationCtrl: ['', Validators.required], // Specialization control with required validator
      durationCtrl: ['', [Validators.required, Validators.min(1)]], // Duration control with required and minimum value validators
      divisionCtrl: ['', Validators.required], // Division/class control with required validator
      passingCtrl: ['', Validators.required] // Year of passing control with required validator
    });
    // affiliation 
    this.affiliationLetterForm = this.fb.group({
      idCtrl: [], // id control with required validator
      nameCtrl: ['', Validators.required], // Name control with required validator
      courseCtrl: ['', Validators.required], // Course control with required validator
      acadYearCtrl: [''], // Academic year control with required validator
      collegeCtrl: ['', Validators.required], // College control with required validator
      specializationCtrl: ['', Validators.required], // Specialization control with required validator
      durationCtrl: ['', [Validators.required, Validators.min(1)]], // Duration control with required and minimum value validators
      divisionCtrl: ['', Validators.required], // Division/class control with required validator
      passingCtrl: ['', Validators.required] // Year of passing control with required validator
    });
    // hrd array
    this.HrdForm = this.fb.group({
      degree: this.fb.array([]),
    });



    // letter for name change
    this.LetterforNameChangeform = this.fb.group({
      firstnameaspermarksheetCtrl:['',Validators.required],
      fathersnameaspermarksheetCtrl:['',Validators.required],
      mothersnameaspermarksheetCtrl:['',Validators.required],
      lastnameaspermarksheetCtrl:['',Validators.required],
      firstnameasperpassportCtrl:['',Validators.required],
      lastnameasperpassportCtrl:['',Validators.required],
      fathersnameasperpassportCtrl:['',Validators.required],
     
    })


    this.userId = this.route.snapshot.queryParamMap.get('userId');
    this.app_id = this.route.snapshot.queryParamMap.get('app_id');
    this.api.view_Application(this.userId).subscribe(response => {
      this.profile = response['userdata']
      this.my_app_data = response['data'];
    });

    this.mark_transcripts();
    this.getPurpose();
    this.getInstructionalDetails();
    this.getAffiliationAdmindetails();
    this.getHrdDetails();
    this.getLetterForNameChange();
    this.getAppliedDetails();
    this.getDocuments();
    this.getWesFormData();
    const now = new Date().getUTCFullYear();
    this.yearsArray = Array(now - (now - 50)).fill('').map((v, idx) => now - idx);
  }
  async getDocuments() {
    var TranscriptData = await this.api.getAllTranscriptDataAppWise(this.userId, this.app_id);
    this.notes_area = TranscriptData['data'].notes_area;

  }
  async getInstructionalDetails() {
    if (this.userId) {
      var response = await this.api.getAdminInstructionalEducation(this.userId,this.app_id);
      response.subscribe(data => {
        this.instructionalData = data['data'];


      })
    }
  }

  async getLetterForNameChange() {
    if (this.userId) {
      var response = await this.api.getAdminLetterForNameChange(this.userId,this.app_id);
      response.subscribe(data => {
        this.LetterForNameChange = data['data'];        
      })
    }

  }
  //get WesFormData
  async getWesFormData() {
    
    if (this.userId) {
      var response = await this.api.getWesFormData(this.userId,this.app_id);
      response.subscribe(data => {
        this.WesFormData = data['data'];
      })
    }
  }
  // Admin side email sent to student
  async sendEmailToStudent(value, email) {
    var response = await this.api.sendEmailToStudent(value, email, this.app_id);
    response.subscribe(data => {


      if (data['status'] == '200') {
        this.toastrService.success('Successfully Email Sent', 'Success', { preventDuplicates: true });
        this.ngOnInit();
      } else {
        this.toastrService.danger('Error while Email Sending', 'danger', { preventDuplicates: true });

      }
    })
  }

  async getHrdDetails() {
    if (this.userId) {
      var userId = this.route.snapshot.queryParamMap.get('userId');
      var response = await this.api.getAdminHrdEducation(userId);
      response.subscribe(data => {
        this.hrdData = data['data'];
        data['data'].forEach((element) => {
          (<FormArray>this.HrdForm.controls['degree']).push(this.buildItemHrd(element.fullName, element.course_name, element.seat_no, element.seat_no_sem5, element.seat_no_sem6, element.prn_no, element.cgpa, element.cgpi, element.transcript_no, element.transcript_date, element.exam_date, element.specialization, element.reference_no, element.degree, element.secondlastsem, element.lastsem, element.verification_type));
        })
      })
    }
  }
  buildItemHrd(val1, val2, val3, val4, val5, val6, val7, val8, val9, val10, val11, val12, val13, val14, val15, val16, val17) {
    return new FormGroup({
      fullnameCtrl: new FormControl(val1, Validators.required),
      course_nameCtrl: new FormControl(val2, Validators.required),
      seat_noCtrl: new FormControl(val3, Validators.required),
      seat_no_sem5Ctrl: new FormControl(val4, Validators.required),
      seat_no_sem6Ctrl: new FormControl(val5, Validators.required),
      prn_noCtrl: new FormControl(val6, Validators.required),
      cgpaCtrl: new FormControl(val7, Validators.required),
      cgpiCtrl: new FormControl(val8, Validators.required),
      transcript_noCtrl: new FormControl(val9, Validators.required),
      transcript_dateCtrl: new FormControl(val10, Validators.required),
      exam_dateCtrl: new FormControl(val11, Validators.required),
      specializationCtrl: new FormControl(val12, Validators.required),
      reference_noCtrl: new FormControl(val13, Validators.required),
      degreeCtrl: new FormControl(val14, Validators.required),
      secondlastsemCtrl: new FormControl(val15, Validators.required),
      lastsemCtrl: new FormControl(val16, Validators.required),
      verificationtypeCtrl: new FormControl(val17, Validators.required),
    })


  }
  // buildItemAffiliation(val1, val2, val3, val4, val5, val6) {
  //   return new FormGroup({
  //     CourseCtrl: new FormControl(val1, Validators.required),
  //     CollegeCtrl: new FormControl(val2, Validators.required),
  //     durationCtrl: new FormControl(val3, Validators.required),
  //     SpecializationCtrl: new FormControl(val4, Validators.required),
  //     yearCtrl: new FormControl(val5, Validators.required),
  //     divisionCtrl: new FormControl(val6, Validators.required),
  //   })


  // }
  // buildItemLetterForNameChange(val1, val2, val3, val4, val5, val6, val7, val8) {
  //   return new FormGroup({
  //     firstnameaspermarksheetCtrl: new FormControl(val1, Validators.required),
  //     fathersnameaspermarksheetCtrl: new FormControl(val2, Validators.required),
  //     mothersnameaspermarksheetCtrl: new FormControl(val3, Validators.required),
  //     lastnameaspermarksheetCtrl: new FormControl(val4, Validators.required),
  //     firstnameasperpassportCtrl: new FormControl(val5, Validators.required),
  //     fathersnameasperpassportCtrl: new FormControl(val6, Validators.required),
  //     lastnameasperpassportCtrl: new FormControl(val7, Validators.required),
  //     reference_noCtrl: new FormControl(val8, Validators.required),
  //   })


  // }
  // buildItemInstructional(val1, val2, val3, val4, val5, val6) {
  //   return new FormGroup({
  //     InstructionalCourseCtrl: new FormControl(val1, Validators.required),
  //     InstructionalCollegeCtrl: new FormControl(val2, Validators.required),
  //     InstructionaldurationCtrl: new FormControl(val3, Validators.required),
  //     InstructionalSpecializationCtrl: new FormControl(val4, Validators.required),
  //     InstructionalyearCtrl: new FormControl(val5, Validators.required),
  //     InstructionaldivisionCtrl: new FormControl(val6, Validators.required),
  //   })


  // }
  async getAffiliationAdmindetails() {
    if (this.userId) {
      var response = await this.api.getAffiliationAdmindetails(this.userId,this.app_id);
      response.subscribe(data => {
        this.affiliationData = data['data'];
      })
    }

  }

  edithrdDetails() {
    // 
    // 
    var hrdFormData = this.HrdForm.controls['degree'].value;
    hrdFormData.forEach(data => {


      this.HrdEditvalue = {
        fullnameCtrl: data.fullnameCtrl,
        course_nameCtrl: data.course_nameCtrl,
        seat_noCtrl: data.seat_noCtrl,
        seat_no_sem5Ctrl: data.seat_no_sem5Ctrl,
        seat_no_sem6Ctrl: data.seat_no_sem6Ctrl,
        prn_noCtrl: data.prn_noCtrl,
        cgpaCtrl: data.cgpaCtrl,
        cgpiCtrl: data.cgpiCtrl,
        transcript_noCtrl: data.transcript_noCtrl,
        transcript_dateCtrl: data.transcript_dateCtrl,
        exam_dateCtrl: data.exam_dateCtrl,
        specializationCtrl: data.specializationCtrl,
        reference_noCtrl: data.reference_noCtrl,
        degreeCtrl: data.degreeCtrl,
        secondlastsemCtrl: data.secondlastsemCtrl,
        lastsemCtrl: data.lastsemCtrl,
        verificationtypeCtrl: data.verificationtypeCtrl
      }

      if (this.userId) {
        var userId = this.route.snapshot.queryParamMap.get('userId');
        var response = this.api.editHrdDetails(this.HrdEditvalue, userId);
        response.subscribe(data => {
          if (data['status'] == 200) {
            this.toastrService.success('Successfully Saved', 'Success', { preventDuplicates: true });
            this.hrdData = data['data'];
            this.ngOnInit(); // Call ngOnInit to refresh the component
          }
        })
      }

    })





  }

  editAffiliationdetails() {

    var AffiliationFormData = this.AffiliationForm.controls['affiliationarray'].value;
    AffiliationFormData.forEach(data => {

      this.AffiliationEditValue = {
        CourseCtrl: data.CourseCtrl,
        CollegeCtrl: data.CollegeCtrl,
        SpecializationCtrl: data.SpecializationCtrl,
        yearCtrl: data.yearCtrl,
        durationCtrl: data.durationCtrl,
        divisionCtrl: data.divisionCtrl,
      }

      if (this.userId) {
        var userId = this.route.snapshot.queryParamMap.get('userId');
        var response = this.api.editAffiliationandInstructionalDetails(this.AffiliationEditValue, userId, 'affiliation');
        response.subscribe(data => {
          if (data['status'] == 200) {
            this.toastrService.success('Successfully Saved', 'Success', { preventDuplicates: true });
            this.affiliationData = data['data'];
          }


        })
      }

    })





  }
  editLetterForNameChange() {

    var LetterForNameChangeData = this.letterforNameChangeForm.controls['letterfornamechangearray'].value;
    LetterForNameChangeData.forEach(data => {

      this.LetterForNameChangeEditValue = {
        firstnameaspermarksheetCtrl: data.firstnameaspermarksheetCtrl,
        fathersnameaspermarksheetCtrl: data.fathersnameaspermarksheetCtrl,
        mothersnameaspermarksheetCtrl: data.mothersnameaspermarksheetCtrl,
        lastnameaspermarksheetCtrl: data.lastnameaspermarksheetCtrl,
        firstnameasperpassportCtrl: data.firstnameasperpassportCtrl,
        fathersnameasperpassportCtrl: data.fathersnameasperpassportCtrl,
        lastnameasperpassportCtrl: data.lastnameasperpassportCtrl,
        reference_noCtrl: data.reference_noCtrl
      }

      if (this.userId) {
        var userId = this.route.snapshot.queryParamMap.get('userId');
        var response = this.api.editLetterForNameChange(this.LetterForNameChangeEditValue, userId);
        response.subscribe(data => {
          if (data['status'] == 200) {
            this.toastrService.success('Successfully Saved', 'Success', { preventDuplicates: true });
            this.LetterForNameChange = data['data'];
          }


        })
      }

    })





  }
  addAffiliationdetails(value) {
    var userId = this.route.snapshot.queryParamMap.get('userId');
    var app_id = this.route.snapshot.queryParamMap.get('app_id');
    const dialogRef = this.dialogService.open(AddAffiliationInstructionaldetails, {
      context: {
        user_id: userId,
        value: value,
        app_id: app_id,
      }
    })
    dialogRef.onClose.subscribe(() => {
      this.ngOnInit(); // Call ngOnInit to refresh the component
    });
  }


  // removespace(){    
  //   var userId=this.route.snapshot.queryParamMap.get('userId');
  //   var response =  this.api.removespace(userId);
  //   response.subscribe(data =>{
  //     if(data['status'] == 200){
  //       this.toastrService.success('Successfully Rmoved Space','Success',{preventDuplicates: true});        
  //     }
  //   })

  // }
  editInstructionaldetails() {

    var InstructionalFormData = this.InstructionalForm.controls['instructionalarray'].value;
    InstructionalFormData.forEach(data => {

      this.InstrcutionalEditValue = {
        InstructionalCourseCtrl: data.InstructionalCourseCtrl,
        InstructionalCollegeCtrl: data.InstructionalCollegeCtrl,
        InstructionalSpecializationCtrl: data.InstructionalSpecializationCtrl,
        InstructionalyearCtrl: data.InstructionalyearCtrl,
        InstructionaldurationCtrl: data.InstructionaldurationCtrl,
        InstructionaldivisionCtrl: data.InstructionaldivisionCtrl,
      }

      if (this.userId) {
        var userId = this.route.snapshot.queryParamMap.get('userId');
        var response = this.api.editAffiliationandInstructionalDetails(this.InstrcutionalEditValue, userId, 'instructional');
        response.subscribe(data => {
          if (data['status'] == 200) {
            this.toastrService.success('Successfully Saved', 'Success', { preventDuplicates: true });
            this.affiliationData = data['data'];
          }


        })
      }

    })





  }
  async saveDetails(event, type) {
    var userId = this.route.snapshot.queryParamMap.get('userId');
    var response = await this.api.saveHrdAdminDetails(userId, type);
    response.subscribe(data => {

      if (data['status'] == 200) {
        this.toastrService.success('Successfull', 'Success', { preventDuplicates: true });

      }
    })
  }

  async mark_transcripts() {
    if (this.userId) {
      if (this.viewFrom == "studentManagement") {
        var userId = this.route.snapshot.queryParamMap.get('userId');
        var TranscriptData = await this.api.getAllTranscriptData(userId, null);
        this.TranscriptData = TranscriptData['data']['userTranscripts'];
        this.MarkListData = TranscriptData['data']['userMarkLists'];
        this.CurriculumData = TranscriptData['data']['userCurriculums'];
        this.letters = TranscriptData['data']['letters'];
        this.ExtraDocument = TranscriptData['data']['userExtraDocument'];
        this.CompetencyLetter = TranscriptData['data']['usercompetency'];

      } else {

        var TranscriptData = await this.api.getAllTranscriptDataAppWise(this.userId, this.app_id);
        this.TranscriptData = TranscriptData['data']['userTranscripts'];
        this.MarkListData = TranscriptData['data']['userMarkLists'];
        this.CurriculumData = TranscriptData['data']['userCurriculums'];
        this.letters = TranscriptData['data']['letters'];
        this.ExtraDocument = TranscriptData['data']['userExtraDocument'];
        this.CompetencyLetter = TranscriptData['data']['usercompetencys'];
        this.NameChangeLetter = TranscriptData['data']['letterfornamechange'];
      }
    }
  }

  getPurpose() {
    if (this.viewFrom == "studentManagement") {
      this.api.getPurposeDetails(this.userId, null).subscribe(data => {
        this.instituteData = data['data'];
      })
    } else {
      this.api.getPurposeDetails(this.userId, this.app_id).subscribe(data => {
        this.instituteData = data['data'];
      })
    }
  }

  Back() {
    this._location.back();
  }

  statusPopup(singleappdata, action) {
    var performAction
    if (action == 'showstatus') {
      performAction = 'showstatus'
    } else if (action == 'showimage') {
      performAction = 'showimage'
    }
    var Appl_id = singleappdata.app_id
    this.dialogService.open(ShowStepperforApp, {
      context: {
        Appc_id: Appl_id,
        performAction: performAction,
        user_id: this.userId
      },
    })
  }

  applndetailsPopup(data) {
    var email = data.email;
    var Appl_id = data.app_id;
    this.dialogService.open(applndetails, {
      context: {
        Appc_id: Appl_id,
        user_id: this.userId,
        email: email
      },
    })
  }

  DownloadTranscript(file_path, name) {
    this.api.downloadTranscript(file_path)
      .subscribe(data => {
        saveAs(data, name);
      });
  }

  openImage(imagename, imgType) {
    this.dialogService.open(transcriptpreview, {
      context: {
        arr: imagename,
        //arr : 'https://mu.admissiondesk.org/uiserver/upload/transcript/20086/HywOaKlgI.pdf',
        imgType: imgType
      },
    });
  }

  changePurpose(institute) {
    this.showOptions = true;
  }

  choosePurpose(event) {
    this.text = ''
    switch (event) {
      case 'fs':
        this.text = 'study'
        this.tbpurpose = true;

        break;
      case 'Physical Copy':
        this.text = 'Physical Copy'
        this.tbpurpose = true;

        break;
      case 'emp':
        this.text = 'employment'
        this.tbpurpose = true;

        break;
      case 'visa':
        this.text = 'visa';
        this.tbpurpose = true;
        //disable this text box and connect with vfx api service 
        break;
      case 'Send a courier internationally':
        this.text = 'Send a courier internationally';
        this.tbpurpose = true;
        //disable this text box and connect with vfx api service 
        break;
      case 'Educational credential evaluators WES':
        this.text = 'Educational credential evaluators WES';
        this.tbpurpose = true;
        //disable this text box and connect with vfx api service 
        break;
      case 'icas':
        this.text = 'ICAS';
        this.tbpurpose = true;
        //disable this text box and connect with vfx api service 
        break;
      case 'eduperspective':
        this.text = 'Educational Perspective';
        this.tbpurpose = true;
        //disable this text box and connect with vfx api service 
        break;
      case 'iqas':
        this.text = 'IQAS';
        this.tbpurpose = true;
        //disable this text box and connect with vfx api service 
        break;
      case 'ces':
        this.text = 'CES';
        this.tbpurpose = true;
        //disable this text box and connect with vfx api service 
        break;

      case 'myiee':
        this.text = 'MYIEE';
        this.tbpurpose = true;
        //disable this text box and connect with vfx api service 
        break;

      case 'ices':
        this.text = 'ICES';
        this.tbpurpose = true;
        //disable this text box and connect with vfx api service 
        break;

      case 'nasba':
        this.text = 'NASBA';
        this.tbpurpose = true;
        //disable this text box and connect with vfx api service 
        break;
      case 'ncees':
        this.text = 'NCEES';
        this.tbpurpose = true;
        //disable this text box and connect with vfx api service 
        break;
      case 'naric':
        this.text = 'NARIC';
        this.tbpurpose = true;
        //disable this text box and connect with vfx api service 
        break;
      case 'nca':
        this.text = 'National Committee on Accreditation';
        this.tbpurpose = true;
        //disable this text box and connect with vfx api service 
        break;
      case 'others':
        this.text = 'others'
        this.tbpurpose = false;

        break;
    }

  }

  addInstitution(institute) {

    var info_type
    var save_type = this.text
    var dialog;

    if (this.text == 'study') {
      info_type = "University Details";
      dialog = 0;
      // }else if(this.text == 'Physical Copy'){
      //   info_type = "Physical Copy";
      //   dialog = 1;
    } else if (this.text == 'visa') {
      info_type = "Counsel"
      dialog = 0;
    } else if (this.text == 'others') {
      info_type = this.otherpurpose
      dialog = 0;
    } if (this.text == 'employment') {
      info_type = "Company"
      dialog = 0;
      // }if( this.text == 'Send a courier internationally'){
      //   info_type = "courier Details";
      //   dialog = 1;
    } if (this.text == 'Educational credential evaluators WES') {
      info_type = "Educational credential evaluators WES";
      dialog = 0;
    }
    if (this.text == 'CES') {
      info_type = "CES";
      dialog = 0;
    }
    if (this.text == 'ICAS') {
      info_type = "ICAS";
      dialog = 0;
    }
    if (this.text == 'NASBA') {
      info_type = "NASBA";
      dialog = 0;
    }
    if (this.text == 'NCEES') {
      info_type = "NCEES";
      dialog = 0;
    }
    if (this.text == 'NARIC') {
      info_type = "NARIC";
      dialog = 0;
    }
    if (this.text == 'Educational Perspective') {
      info_type = "Educational Perspective";
      dialog = 0;
    }
    if (this.text == 'IQAS') {
      info_type = "IQAS";
      dialog = 0;
    }
    if (this.text == 'MYIEE') {
      info_type = "MYIEE";
      dialog = 0;
    }
    if (this.text == 'ICES') {
      info_type = "ICES";
      dialog = 0;
    }
    if (this.text == 'National Committee on Accreditation') {
      info_type = "National Committee on Accreditation";
      dialog = 0;
    }
    var fntype = 'add'

    if (dialog === 0) {
      this.dialogService.open(Addinstitutiondialog, {
        context: {
          institute: institute,
          function_type: fntype,
          info_type: info_type,
          save_type: save_type
        },
      }).onClose.subscribe((name: any) => {
        // if(info_type == 'Educational credential evaluators WES'){
        //   this.confirmationService.confirm({
        //     message: 'Email Id is not required for Educational Credential Evaluators (WES)',
        //     header: 'Information',
        //     key : 'alertKey',
        //     icon: 'pi pi-check',
        //     accept: () => {
        //     }
        //   });
        // }

        // this.api.resetApplicationStage("verified",this.app_id).subscribe(data=>{
        this.getPurpose();
        // })
      })
      // }else if( dialog === 1){
      //   this.dialogService.open(Adddetailsdia, {
      //     context: {
      //       institute : institute,
      //       function_type : fntype,
      //       info_type : info_type,
      //       save_type : save_type
      //     },
      //   }).onClose.subscribe((name: any) => {
      //       this.ngOnInit();
      //   })
    }
  }
  mergeDocuments() {

    this.api.createMerged(this.app_id, this.userId).subscribe(data => {
      if (data['status'] == 200) { }
    });
  }
  changeFlag(event) {

    if (event != null) {
      this.otherFlag = true;
      this.otherpurpose = event.target.value;
    }

  }

  admin_pass_reset(email) {
    var user_mail = email;

    if (user_mail == this.profile.email) {
      this.api.admin_pass_reset(user_mail).subscribe(data => {

        if (data['status'] == 200) {
          this.toastrService.success('Password is Changed to 123456', 'Success', { preventDuplicates: true });
        } else if (data['status'] == 400) {
          this.toastrService.danger('Something is Wrong Please Contact The Admin', 'Error', { preventDuplicates: true });
        }
      })
    } else {
      alert("Something is Wrong Please Contact The Admin");
    }
  }

  admin_doc_reset() {
    var user_id = this.userId;

    if (user_id !== undefined) {
      this.api.admin_document_reset(user_id).subscribe(data => {

        if (data['status'] == 200) {
          this.toastrService.show('Document Is Deleted From Database', 'Success', { preventDuplicates: true });
        } else if (data['status'] == 400) {
          this.toastrService.show('Student Have Paid the Fees. Cannot Delete Data From The Database.', 'Warning', { preventDuplicates: true });
        } else {
          this.toastrService.show('Something Went Wrong.', 'Error', { preventDuplicates: true });
        }
      })
      this.ngOnInit();
    } else {
      alert("Something is Wrong");
    }
  }

  onDeleteTranscript(data, type: string) {
    let types = type;


    // this.confirmationService.confirm({
    //   message: 'Do you want to delete this file??',
    //   header: 'Confirmation',
    //   icon: 'pi pi-exclamation-triangle',
    //   accept: () => {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { type: 'DeleteTranscript' },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        var duration = 10000;
        this.position = 'top-right';
        this.status = 'success';
        this.api.deleteTotalMarklist(data, types).subscribe(data => {
          var yourMessage = data['message']
          if (data['status'] == 200) {
            this.status = 'success';
            this.toastrService.show(
              ` ` + yourMessage, 'SUCCESS', {
              duration
            }
            );
            this.ngOnInit();
          }
        })
      }
    });
  }
  onDetailsTranscript(created_at, updated_at) {
    this.created_at = created_at;
    this.updated_at = updated_at

  }
  admin_name_change(value) {
    if (value == 'name') {
      this.display_first = true;
    } else if (value == 'location') {
      this.currentLocation = true;
    }
  }

  name_change(firstname: string, lastname: string) {
    this.userId = this.route.snapshot.queryParamMap.get('userId');
    var firstName = firstname;
    var lastName = lastname;
    if (firstname != undefined) {
      this.api.name_changes(firstName, lastName, this.userId).subscribe(data => {

        if (data['status'] == 200) {
          this.toastrService.success('Name Changed Successfully', 'Success', { preventDuplicates: true });
          this.ngOnInit();
          this.display_first = false;
        }
        else {
          this.toastrService.danger('Something Went Wrong', 'Error', { preventDuplicates: true });
        }
      })
    } else {
      alert('Something Went Wrong');
    }
  }



  onBeforeSend(event) {
    this.loadingimg = true;
    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
      if (token.isValid()) {
        this.currenttoken = token;
        // this.loadingimg = false;
        event.xhr.setRequestHeader("Authorization", `Bearer ` + this.currenttoken);
      }
    });

  }

  onUpload(event) {

    const reader = new FileReader();
    var duration = 10000;
    this.position = 'top-right';
    this.status = 'success';
    if (event.files && event.files.length) {
      const [file] = event.files;
      reader.readAsDataURL(file);
      var json = JSON.parse(event.xhr.response);

      var yourData = json.Data; // or json["Data"]
      var yourStatus = json.status; // or json["Data"]
      var yourMessage = json.message;
      if (yourStatus == 200) {
        this.status = 'success';
        this.loadingimg = false;
        this.toastrService.show(` ` + yourMessage, 'SUCCESS', { duration });
        this.mark_transcripts();
        this.getWesFormData();
      } else if (yourStatus == 401) {
        this.status = 'error';
        // this.loading = false;
        this.toastrService.show(
          ` ` + yourMessage, 'DANGER', {
          duration
        }
        );

      } else if (yourStatus == 400) {
        this.status = 'error';
        // this.loading = false;
        this.toastrService.show(
          ` ` + yourMessage, 'DANGER', {
          duration
        }
        );
      }
      // this.loading = false;
      // this.ref.close(file);
    }

  }

  onSelect($event: any): void {
    var maxFileSize = 5000000;
    var imgArr = $event.files[0].name.split('.');
    var extension = imgArr[imgArr.length - 1].trim();
    if ($event.files[0].size > maxFileSize) {
      this.confirmationService.confirm({
        message: 'Maximum file size should be 5 MB.',
        header: 'Invalid File Size',
        icon: 'pi pi-info-circle',
        rejectVisible: false,
        acceptLabel: 'Ok'
      });
    }


    //this.loadingimg=true;
  }

  uploadMoreTranscript() {
    //this.showsingleClgTran = true;

    var duration = 10000;
    this.position = 'top-right';
    this.dialogService.open(UploadDocuments, {
      context: {
        user_id: this.userId,
        app_id: this.app_id
      },
    }).onClose
      .subscribe(
        (data: any) => {
          this.mark_transcripts();
        })
  }

  selectCurrentLocation(event) {
    this.api.setCurrentLocation(event, this.userId).subscribe(data => {
      if (data['status'] == 200) {
        this.toastrService.success('Current Location Set', '', {
          duration: 3000
        })
        this.currentLocation = false;
        this.ngOnInit();
      }


    })
  }
  addAppid(type) {
    this.api.SetAppId(this.app_id, this.userId, type).subscribe(data => {
      if (data['status'] == 200) {
        this.toastrService.success('App id updated', '', {
          duration: 3000
        })
        this.ngOnInit();
      } else {
        this.toastrService.danger('App id not updated', '', {
          duration: 3000
        })
      }
    })
  }

  //select verification type
  selectverificationType(event) {

    var userId = this.route.snapshot.queryParamMap.get('userId');
    var response = this.api.saveHrdAdminDetails(userId, event);
    response.subscribe(data => {

      if (data['status'] == 200) {
        this.toastrService.success('Successfull', 'Success', { preventDuplicates: true });

      }
    })
  }


  changeClgNames(id, name, type) {
    this.dialogService.open(changeClgNameComponent, {
      context: {
        id: id,
        name: name,
        type: type
      }
    }).onClose.subscribe(data => {
      this.ngOnInit();
    })
  }
  selectImage(index: number): void {
    if (index = 0) {
      this.selectedIndex = 0;
    }
    this.selectedIndex = index;
  }
  onPrevClick(): void {
    this.selectedIndex--;
  }

  onNextClick(): void {
    this.selectedIndex++;
  }


  singleFile(row) {
    var response = this.api.removespace(this.userId);
    response.subscribe(data => {
      if (data['status'] == 200) {
        this.confirmationService.confirm({
          message: 'Do you want to continue with single file?',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            var type = "verified"
            this.loading = true;
            this.api.sendNotifications(row.app_id, type)
              .subscribe(result => { });
            const run = async () => {
              this.process.push(row.app_id)
              this.api.signpdf(row.app_id, 'single', '', '').subscribe(result => {
                this.loading = false;
                if (result['status'] == 200) {
                  this.toastrService.success(result['message'], 'Success', { duration: 3000 });
                  this.router.navigateByUrl('pages/adminVerified');
                } else if (result['status'] == 400) {
                  this.toastrService.danger(result['message'], 'Error', { duration: 3000 });
                  if (result['message'] == 'Some documents not signed so can not proceed the application') {
                    var index = this.process.indexOf(result['app_id'])
                    this.process.splice(index, 1)
                    this.api.resetErrorApplication(row.app_id).subscribe(data => {
                      if (data['status'] = "200")
                        this.toastrService.success("application" + row.app_id + " reset to verified", 'Success', { duration: 3000 });
                    })
                  }
                  this.ngOnInit();
                }
              })
            }
            run().catch(err => console.error(err))

          },
          reject: () => {
          }
        });

      }
    })

  }

  mulitpleFile(row, value) {
    var response = this.api.removespace(this.userId);
    response.subscribe(data => {
      if (data['status'] == 200) {
        this.confirmationService.confirm({
          message: 'Do you want to continue with multiple files?',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            var type = "verified";
            this.api.sendNotifications(row.app_id, type).subscribe(result => { });
            const run = async () => {
              this.process.push(row.app_id)
              this.api.signpdf(row.app_id, 'multiple', null, value).subscribe(result => {
                this.loading = false;
                if (result['status'] == 200) {
                  this.toastrService.success(result['message'], 'Success', { duration: 3000 });
                  this.router.navigateByUrl('pages/adminVerified');
                } else if (result['status'] == 400) {
                  this.toastrService.danger(result['message'], 'Error', { duration: 3000 });
                  if (result['message'] == 'Some documents not signed so can not proceed the application') {
                    var index = this.process.indexOf(result['app_id'])
                    this.process.splice(index, 1)
                    this.api.resetErrorApplication(row.app_id).subscribe(data => {
                      if (data['status'] = "200")
                        this.toastrService.success("application" + row.app_id + " reset to verified", 'Success', { duration: 3000 });
                    })
                  }
                  this.ngOnInit();
                }
              })
            }
            run().catch(err => console.error(err))

          },
          reject: () => {
          }
        });
      }
    })

  }
  sendEmailPurpose(studentEmail) {
    this.studentEmail = studentEmail;
    this.displayPurpose = true;
  }
  sendMailOfPurpose(event) {

    this.api.sendEmailToStudent(event, this.studentEmail, this.app_id).subscribe(data => {
      if (data['status'] == 200) {
        this.toastrService.success('Successfully Email Sent', 'Success', { preventDuplicates: true });
        this.studentEmail = '';
        this.displayPurpose = false;
      }
    })

  }

  getAppliedDetails() {
    this.api.getAppliedDetails(this.app_id, this.userId).subscribe(data => {
      if (data['status'] == 200) {
        this.appliedDetails = data['data'];
        if (this.appliedDetails.CompetencyLetter != null && this.appliedDetails.CompetencyLetter != false) {
          this.competencyLetterChecked = true;
        }
        if (this.appliedDetails.LetterforNameChange != null && this.appliedDetails.LetterforNameChange != false) {
          this.letterforNameChangeChecked = true;
        }
        if (this.appliedDetails.affiliation != null && this.appliedDetails.affiliation != false) {
          this.affiliationChecked = true;
        }
        if (this.appliedDetails.instructionalField != null && this.appliedDetails.instructionalField != false) {
          this.instructionalChecked = true;
        }
        if (this.appliedDetails.educationalDetails != null && this.appliedDetails.educationalDetails != false) {
          this.educationalChecked = true;
        }
        if (this.appliedDetails.curriculum != null && this.appliedDetails.curriculum != false) {
          this.curriculumChecked = true;
        }
        if (this.appliedDetails.gradToPer != null && this.appliedDetails.gradToPer != false) {
          this.gradToPerChecked = true;
        }

      }
    });
  }
  appliedDetail() {
    this.appliedDetailsChange = true;

  }

  onCheckboxChange(event, value) {

    if (event.target.checked == true) {
      // error 
    } else {
      if (value == 'education') {
        this.educationalChecked = event.target.checked
        if (event.target.checked == false) {
          this.applied.push('educationalDetails')
        }

      }
      if (value == 'curriculum') {
        this.curriculumChecked = event.target.checked
        if (event.target.checked == false) {
          this.applied.push('curriculum')
        }
      }
      if (value == 'gradeToPer') {
        this.gradToPerChecked = event.target.checked
        if (event.target.checked == false) {
          this.applied.push('gradToPer')
        }
      }
      if (value == 'affiliation') {
        this.affiliationChecked = event.target.checked
        if (event.target.checked == false) {
          this.applied.push('affiliation')
        }
      }
      if (value == 'competencyLetter') {
        this.competencyLetterChecked = event.target.checked
        if (event.target.checked == false) {
          this.applied.push('CompetencyLetter')
        }
      }
      if (value == 'letterforNameChange') {
        this.letterforNameChangeChecked = event.target.checked
        if (event.target.checked == false) {
          this.applied.push('LetterforNameChange')
        }
      }
      if (value == 'instructional') {
        this.instructionalChecked = event.target.checked
        if (event.target.checked == false) {
          this.applied.push('instructionalField')
        }
      }
    }

  }

  updtaeApplied() {

    this.api.updatedAppliedDetails(this.applied, this.userId, this.app_id).subscribe((data: any) => {
      if (data.status == 200) {
        this.toastrService.success(data.message, 'Success', { preventDuplicates: true });
        this.appliedDetailsChange = false;
        this.ngOnInit();
      } else if (data.status == 500) {
        this.appliedDetailsChange = false;
        this.toastrService.danger(data.message, 'Danger', { preventDuplicates: true });
        this.ngOnInit();

      }
    })

  }

  viewRemoveDegree() {
    this.degreeDetailsChange = true;

  }
  removeDegree(event) {

    this.api.removeDegree(event, this.app_id).subscribe((data: any) => {
      if (data.status == 200) {
        this.toastrService.success(data.message, 'Success', { preventDuplicates: true });
        this.degreeDetailsChange = false;
        this.ngOnInit();
      } else {
        this.toastrService.success(data.message, 'Danger', { preventDuplicates: true });
        this.degreeDetailsChange = false;
        this.ngOnInit();
      }
    })
  }

  candidateTypeChange() {
    this.candidateType = true;
  }
  candidateRemarks(event) {
    this.api.candidateRemarksType(event, this.app_id).subscribe(data => {
      if (data['status'] == 200) {
        this.toastrService.success(event + ' Candidate Remark', 'Success', { preventDuplicates: true });
        this.candidateType = false;
        this.ngOnInit();
      } else {
        this.toastrService.danger('Error while set Candidate Remark', 'danger', { preventDuplicates: true });
        this.ngOnInit();
        this.candidateType = false;

      }
    })
  }
  removeCandidateType() {
    this.api.candidateRemarksRemove(this.app_id).subscribe(data => {
      if (data['status'] == 200) {
        this.toastrService.success(data['message'], 'Success', { preventDuplicates: true });
        this.ngOnInit();

      } else {
        this.toastrService.danger(data['message'], 'Danger', { preventDuplicates: true });
        this.ngOnInit();

      }
    })
  }

  addNotes(type) {
    if (type == 'notes') {
      this.addNote = true;
      this.notesType = 'notes';
      this.notes = this.notes_area;
    }

  }

  closeNoteArea() {
    this.addNote = false;
    this.notesType = '';
  }

  saveNotes(type) {

    this.api.saveNotes(this.app_id, this.notes, type).subscribe(data => {

      // this.notes_area = data['data'];
      if (data['status'] == 200) {
        this.addNote = false;
        this.notesType = '';
        this.toastrService.success("Notes Added", '', {
          duration: 3000
        })
        this.ngOnInit();
      } else {
        this.toastrService.danger("Something Went wrong", '', {
          duration: 3000
        })
      }

    })
  }

  edit(id,type) {       
    if (type === 'LetterForNameChange') {
      this.formEnable = true;
  
      this.api.getDataById(id, type).subscribe(data => {
        if (data['status'] === 200) {
          this.namechangeletterdetails = data['data'];
  
          // Use the form group to set values
          this.LetterforNameChangeform.patchValue({
            firstnameaspermarksheetCtrl: this.namechangeletterdetails.firstnameaspermarksheet,
            fathersnameaspermarksheetCtrl: this.namechangeletterdetails.fathersnameaspermarksheet,
            mothersnameaspermarksheetCtrl: this.namechangeletterdetails.mothersnameaspermarksheet,
            lastnameaspermarksheetCtrl: this.namechangeletterdetails.lastnameaspermarksheet,
            firstnameasperpassportCtrl: this.namechangeletterdetails.firstnameasperpassport,
            fathersnameasperpassportCtrl: this.namechangeletterdetails.fathersnameasperpassport,
            lastnameasperpassportCtrl: this.namechangeletterdetails.lastnameasperpassport,
          });
  
          this.NameChangeId = this.namechangeletterdetails.id;
          this.app_id_namechange = this.namechangeletterdetails.app_id;
        }
      });
    }else if(type === 'Instructional'){
      this.instructionalEnable = true;
      this.api.getDataById(id,type).subscribe(data => {
        if (data['status'] === 200) {

          this.viewInstructionalDetails = data['data'];            
          let yearString = this.viewInstructionalDetails.yearofpassing;
          let yearRegex = /\b\d{4}\b/; // Match 4 digits as a whole word (year)
          let yearMatch = yearString.match(yearRegex);
          let year = yearMatch ? yearMatch[0] : null; // Extracted year          
          this.sameCollege = this.viewInstructionalDetails.collegeType; 
          this.instructionalForm.controls.idCtrl.setValue(this.viewInstructionalDetails.id)
          this.instructionalForm.controls.nameCtrl.setValue(this.viewInstructionalDetails.studentName)
          this.instructionalForm.controls.courseCtrl.setValue(this.viewInstructionalDetails.courseName)
          this.instructionalForm.controls.collegeCtrl.setValue(this.viewInstructionalDetails.collegeName)
          this.instructionalForm.controls.specializationCtrl.setValue(this.viewInstructionalDetails.specialization)
          this.instructionalForm.controls.durationCtrl.setValue(this.viewInstructionalDetails.duration)
          this.instructionalForm.controls.passingCtrl.setValue(parseInt(year))
          this.instructionalForm.controls.acadYearCtrl.setValue(this.viewInstructionalDetails.academicYear)
          this.instructionalForm.controls.divisionCtrl.setValue(this.viewInstructionalDetails.division)
          this.instructionalId = this.viewInstructionalDetails.id
        }
      })
    }else{
      this.formEnableAffiliation = true;
      this.api.getDataById(id,type).subscribe(data => {
        if (data['status'] === 200) {
          this.viewInstructionalDetails = data['data'];          
          let yearString = this.viewInstructionalDetails.yearofpassing;
          let yearRegex = /\b\d{4}\b/; // Match 4 digits as a whole word (year)
          let yearMatch = yearString.match(yearRegex);
          let year = yearMatch ? yearMatch[0] : null; // Extracted year           
          this.sameCollegeAffiliation = this.viewInstructionalDetails.collegeType;
          this.affiliationLetterForm.controls.idCtrl.setValue(this.viewInstructionalDetails.id)
          this.affiliationLetterForm.controls.nameCtrl.setValue(this.viewInstructionalDetails.studentName)
          this.affiliationLetterForm.controls.courseCtrl.setValue(this.viewInstructionalDetails.courseName)
          this.affiliationLetterForm.controls.collegeCtrl.setValue(this.viewInstructionalDetails.collegeName)
          this.affiliationLetterForm.controls.specializationCtrl.setValue(this.viewInstructionalDetails.specialization)
          this.affiliationLetterForm.controls.durationCtrl.setValue(this.viewInstructionalDetails.duration)
          this.affiliationLetterForm.controls.passingCtrl.setValue(parseInt(year))
          this.affiliationLetterForm.controls.acadYearCtrl.setValue(this.viewInstructionalDetails.academicYear)
          this.affiliationLetterForm.controls.divisionCtrl.setValue(this.viewInstructionalDetails.division)
          this.affiliationId = this.viewInstructionalDetails.id
          
        }
      })
    }
  }
  delete(id, type) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { type: 'DeleteLetter' },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.api.delete(id, type).subscribe(data => {
          if (data['status'] == 200) {
            this.toastrService.success(data['message'], 'Success', {
              duration: 3000
            })
            this.ngOnInit();
            this.mark_transcripts();
          } else {
            this.toastrService.danger("Details Not Deleted", 'Danger', {
              duration: 3000
            })
            this.ngOnInit();
          }
        })
      }
    });

  }
  saveData(type) {
    if (type == 'LetterForNameChange') {
      var data = {
        "firstnameaspermarksheetCtrl": this.LetterforNameChangeform.controls.firstnameaspermarksheetCtrl.value,
        "fathersnameaspermarksheetCtrl": this.LetterforNameChangeform.controls.fathersnameaspermarksheetCtrl.value,
        "mothersnameaspermarksheetCtrl": this.LetterforNameChangeform.controls.mothersnameaspermarksheetCtrl.value,
        "lastnameaspermarksheetCtrl": this.LetterforNameChangeform.controls.lastnameaspermarksheetCtrl.value,
        "firstnameasperpassportCtrl": this.LetterforNameChangeform.controls.firstnameasperpassportCtrl.value,
        "fathersnameasperpassportCtrl": this.LetterforNameChangeform.controls.fathersnameasperpassportCtrl.value,
        "lastnameasperpassportCtrl": this.LetterforNameChangeform.controls.lastnameasperpassportCtrl.value,
        "userId": this.userId,
        "appId": this.app_id,
        "id" :  this.NameChangeId

      }
      this.api.saveForm(data, "LetterForNameChange").subscribe(data => {
        if (data['status'] == 200) {
          this.formEnable = false;
          this.toastrService.success(data['message'], 'Success', { duration: 3000 });
          this.ngOnInit();
        } else {
          this.toastrService.danger('Details Not Saved!!', 'Danger', { duration: 3000 });
        }
      })
    } else if (type == 'Instructional') {
      var instructionalData = {
        "nameCtrl": this.instructionalForm.controls.nameCtrl.value,
        "specializationCtrl": this.instructionalForm.controls.specializationCtrl.value,
        "durationCtrl": this.instructionalForm.controls.durationCtrl.value,
        "divisionCtrl": this.instructionalForm.controls.divisionCtrl.value,
        "passingCtrl": this.instructionalForm.controls.passingCtrl.value,
        "acadYearCtrl": this.instructionalForm.controls.acadYearCtrl.value,
        "userId": this.userId,
        "appId": this.app_id,
        "id" :  this.instructionalId
      }
      this.api.saveForm(instructionalData, "Instructional").subscribe(data => {        
        if (data['status'] == 200) {
          this.instructionalEnable = false;
          this.toastrService.success(data['message'], 'Success', { duration: 3000 });
          this.ngOnInit();
        } else {
          this.toastrService.danger('Details Not Saved!!', 'Danger', { duration: 3000 });
        }
      })
    } else {
      var instructionalData = {
        "nameCtrl": this.affiliationLetterForm.controls.nameCtrl.value,
        "specializationCtrl": this.affiliationLetterForm.controls.specializationCtrl.value,
        "durationCtrl": this.affiliationLetterForm.controls.durationCtrl.value,
        "divisionCtrl": this.affiliationLetterForm.controls.divisionCtrl.value,
        "passingCtrl": this.affiliationLetterForm.controls.passingCtrl.value,
        "acadYearCtrl": this.affiliationLetterForm.controls.acadYearCtrl.value,
        "userId": this.userId,
        "appId": this.app_id,
        "id" :  this.affiliationId
      }
      this.api.saveForm(instructionalData, "Affiliation").subscribe(data => {
        if (data['status'] == 200) {
          this.formEnableAffiliation = false;
          this.toastrService.success(data['message'], 'Success', { duration: 3000 });
          this.ngOnInit();
        } else {
          this.toastrService.danger('Details Not Saved!!', 'Danger', { duration: 3000 });
        }
      })
    }
  }

  OnlyNoAllowed(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {

      return false;
    }
    return true;
  }


  addWesFormData(type: any, institute: any) {    
    const saveType = 'Educational credential evaluators WES';
    let infoType: any;
    let dialog: number | null = null; // Initialize dialog as null
  
    if (type === 'edit' || type === 'add') {
      if (institute === saveType) {
        infoType = saveType;
        dialog = 3;
      }
    }
  
    // Open dialog if dialog type is determined
    if (dialog === 3) {
      this.dialogService.open(AddWes_Form, {
        context: {
          institute: institute,
          function_type: type,
          info_type: infoType,
          save_type: saveType,
          bach_Data : '',

        },
      }).onClose.subscribe(() => {
        this.ngOnInit(); // Refresh or re-initialize the component
      });
    }
  }
  
}
