import { Component, Input, ViewChild } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { NbAuthService, NbAuthJWTToken } from '@nebular/auth';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ApiService } from '../../shared/api.service';
import { NbToastrService, NbDialogService } from '@nebular/theme';

@Component({
  selector: 'nb-dialog',
  template: `

<nb-card status="success">
  <nb-card-body>

    <form [formGroup]="InstructionalForm">
      <div class="row">
        <nb-card>
          <nb-card-header>
            {{value}} Details
          </nb-card-header>
          <nb-card-body style="width: 645px;">
            <div class="row">
              <div class="col-md-6">Course</div>
              <div class="col-md-6">
                <mat-form-field>
                  <mat-label>Select a Course</mat-label>
                  <mat-select formControlName="instructionalCourseCtrl" [(value)]="selectionCourseChange"
                    (selectionChange)="onSelectionChange()">
                    <mat-option *ngFor="let type of course" [value]="type">{{ type.course }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <hr />

            <div class="row">
              <div class="col-md-6">College</div>
              <div class="col-md-6">
                <mat-form-field>
                  <mat-label>Select a college</mat-label>
                  <mat-select formControlName="instructionalCollegeCtrl" [(value)]="selectedCollege"
                    (selectionChange)="onSelectionChange()" [panelClass]="'searchable-select'">
                    <input matInput (keyup)="filterColleges($event.target.value)" placeholder="Search colleges">
                    <mat-option *ngFor="let college of filteredCollegeList" [value]="college">
                      {{ college.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <hr />

            <div class="row">
              <div class="col-md-6">Specialization</div>
              <div class="col-md-6">
                <input nbInput type="text" class="form-control" formControlName="instructionalSpecializationCtrl">
              </div>
            </div>
            <hr />

            <div class="row">
              <div class="col-md-6">Year of Passing</div>
              <div class="col-md-6">
                <mat-form-field>
                  <mat-label>Year of Passing</mat-label>
                  <mat-select formControlName="instructionalyearCtrl">
                    <mat-option *ngFor="let year of yearsArray" [value]="year">{{ year }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <hr />

            <div class="row">
              <div class="col-md-6">Duration of Course</div>
              <div class="col-md-6">
                <input nbInput formControlName="instructionaldurationCtrl" type="number" placeholder="Example - 3"
                  (keypress)="OnlyNoAllowed($event)">
              </div>
            </div>
            <hr />

            <div class="row">
              <div class="col-md-6">Passed with</div>
              <div class="col-md-6">
                <mat-form-field>
                  <mat-label>Division/Class</mat-label>
                  <mat-select formControlName="instructionaldivisionCtrl">
                    <mat-option value="Distinction">Distinction</mat-option>
                    <mat-option value="FirstClass">First Class</mat-option>
                    <mat-option value="SecondClass">Second Class</mat-option>
                    <mat-option value="ThirdClass">Third Class</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <hr />

            <div class="row">
              <div class="col-md-6">Academic Year</div>
              <div class="col-md-6">
                <mat-form-field>
                  <mat-label>Academic Year</mat-label>
                  <mat-select formControlName="instructionalacadYearCtrl">
                    <mat-option value="DFirst Year">First Year</mat-option>
                    <mat-option value="FirstClass">Second Year</mat-option>
                    <mat-option value="Third Year">Third Year</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <hr />

            <button type="button" pButton label="Save" [disabled]="!InstructionalForm.valid"
              (click)="save(type)"></button>
          </nb-card-body>
        </nb-card>
      </div>
    </form>


    <nb-card-footer>
      <button nbButton (click)="dismiss()">close</button>
    </nb-card-footer>
  </nb-card-body>
</nb-card>
`,
styles: [`
 /* Add to your component CSS file */
.searchable-select .mat-form-field {
  width: 100%;
  padding: 8px;
}

.searchable-select .mat-select-content {
  display: flex;
  flex-direction: column;
}

.searchable-select .mat-option {
  padding: 0;
}

`]
})
export class AddAffiliationInstructionaldetails {

  @Input() value: string;
  @Input() app_id: string;
  @Input() user_id: string;
  //  @Input() type: string;

  instructionalCollegeCtrl = new FormControl();
  InstructionalForm: FormGroup;
  instructionalData: [];
  affiliationData: [];
  collegeList = [];
  course = [];
  yearsArray = [];
  selectedCollege: string;
  selectionCourseChange: string;
  type: string
  linearMode = true;
  @ViewChild('progressStepper') stepper;
  filteredCollegeList: any[];



  constructor(protected ref: NbDialogRef<AddAffiliationInstructionaldetails>,
    protected api: ApiService,
    private fb: FormBuilder,
    private authService: NbAuthService,
    private toastrService: NbToastrService,
  ) {
    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {

      });

  }

  ngOnInit() {
    this.type = this.value;
    this.InstructionalForm = this.fb.group({
      instructionalCourseCtrl: ['', Validators.required],
      instructionalCollegeCtrl: ['', Validators.required],
      instructionalSpecializationCtrl: ['', Validators.required],
      instructionalyearCtrl: ['', Validators.required],
      instructionaldurationCtrl: ['', Validators.required],
      instructionaldivisionCtrl: ['', Validators.required],
      instructionalacadYearCtrl: [''],

    });
    this.api.getCollegeList().subscribe((data) => {      
      if (data['data'] != undefined) {
        data['data'].forEach((element) => {
          this.collegeList.push(element)

        })
        this.filteredCollegeList = this.collegeList;
      }
      
    })

    this.api.getCourse(this.app_id, this.user_id).subscribe((data) => {
      if (data['data'] != undefined) {
        data['data'].forEach((element) => {
          this.course.push(element)


        })
      }
    })

    const now = new Date().getUTCFullYear();
    this.yearsArray = Array(now - (now - 50)).fill('').map((v, idx) => now - idx);
  }

  save(value) {

    var data = this.InstructionalForm.value;
    this.api.saveAffiliationInstructional(this.user_id, value, this.app_id, data).subscribe(data => {
      if (data['status'] == 200) {
        this.toastrService.success(data['message'], 'Success', { duration: 3000 });
        this.ref.close();
        this.ngOnInit();

      } else {
        this.toastrService.danger('Details Not Saved!!', 'Danger', { duration: 3000 });
      }
    })



  }
  dismiss() {
    this.ref.close();

  }

  onClose() {
    this.ngOnInit();
  }

  onSelectionChange() {
    this.selectedCollege
    this.selectionCourseChange
  }

  OnlyNoAllowed(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {

      return false;
    }
    return true;
  }

  filterColleges(searchValue: string) {
    this.filteredCollegeList = this.collegeList.filter(college =>
      college.name.toLowerCase().includes(searchValue.toLowerCase())
    );
  
    if (searchValue.trim() === '' && this.filteredCollegeList.length > 0) {
      this.selectedCollege = this.filteredCollegeList[0];
    }
  }
  

}