import { Component,HostListener, Input } from '@angular/core';
import { NbDialogRef ,NbToastrService} from '@nebular/theme';

import { NbAuthService, NbAuthJWTToken } from '@nebular/auth';
import { FormBuilder, FormGroup,Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { Router , ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from '../../../../shared/api.service';
import { CountriesService } from '../../../../@core/data/countries.service';
import { locateHostElement } from '@angular/core/src/render3/instructions';
import {ConfirmationService} from 'primeng/api';
import { config } from '../../../../../../config';
import { NbDialogService } from '@nebular/theme';
import { transcriptpreview } from '../../../admin-dashboard/dialog/transcriptpreview.component';

@Component({
selector: 'nb-dialog',
providers:[ConfirmationService],
template: `
<nb-card status="success">
  <nb-card-header style="text-align:center;font-size: 150%;">{{info_type}}  
    <div class="right" (click)="dismiss();">
      <i title="Back"  class="fas fa-arrow-left fa-pull-right fa-border"></i>
    </div> 
  </nb-card-header>
  
  <nb-card-body  style="width : 943px">
  <form [formGroup]="wesForm">
    
  <div class="row">
      <div class="col-md-12" style ="color: red;">
        <p >Note: Kindly feed in the details which are to be imprinted on the Doctoral Degree form.
        </p>
      </div>
    </div>
    <div class="row">
      
    <div class="col-md-3">Current Address</div>
      <div class="col-md-1">
      </div>
      <div class="col-md-6" >
        <input type="text" [(ngModel)]="address" class="form-control" formControlName="addressCtrl"  [ngClass]="{'form-control-danger': wesForm.controls.addressCtrl.invalid && (wesForm.controls.addressCtrl.dirty || wesForm.controls.addressCtrl.touched)}"> 
      </div>

      <div class="col-md-3">State</div>
      <div class="col-md-1">
      </div>
      <div class="col-md-6" >
        <input type="text" [(ngModel)]="state" class="form-control" formControlName="stateCtrl"  [ngClass]="{'form-control-danger': wesForm.controls.stateCtrl.invalid && (wesForm.controls.stateCtrl.dirty || wesForm.controls.stateCtrl.touched)}"> 
      </div>

      
      <div class="col-md-3">Postal Code</div>
      <div class="col-md-1">
      </div>
      <div class="col-md-6" >
        <input type="text" [(ngModel)]="postalcode" class="form-control" formControlName="postalCtrl"  [ngClass]="{'form-control-danger': wesForm.controls.postalCtrl.invalid && (wesForm.controls.postalCtrl.dirty || wesForm.controls.postalCtrl.touched)}"> 
      </div>


      <div class="col-md-3">City</div>
      <div class="col-md-1">
      </div>
      <div class="col-md-6" >
        <input type="text" [(ngModel)]="city" class="form-control" formControlName="cityCtrl"  [ngClass]="{'form-control-danger': wesForm.controls.cityCtrl.invalid && (wesForm.controls.cityCtrl.dirty || wesForm.controls.cityCtrl.touched)}"> 
      </div>

      <div class="col-md-3">Date Of Birth</div>
      <div class="col-md-1">
      </div>
      <div class="col-md-6" >
        <input type="date" [(ngModel)]="dob" class="form-control" formControlName="dobCtrl"  [ngClass]="{'form-control-danger': wesForm.controls.dobCtrl.invalid && (wesForm.controls.dobCtrl.dirty || wesForm.controls.dobCtrl.touched)}"> 
      </div>


      <div class="col-md-3">Institution Name</div>
      <div class="col-md-1">
      </div>
      <div class="col-md-6" >
        <input type="text" [(ngModel)]="institutionname" class="form-control" formControlName="institutionnameCtrl"  [ngClass]="{'form-control-danger': wesForm.controls.institutionnameCtrl.invalid && (wesForm.controls.institutionnameCtrl.dirty || wesForm.controls.institutionnameCtrl.touched)}"> 
      </div>

      <div class="col-md-3">From :</div>
      <div class="col-md-1">
      </div>
      <div class="col-md-6" >
        <input type="month" [(ngModel)]="fromdate" class="form-control" formControlName="fromdateCtrl"  [ngClass]="{'form-control-danger': wesForm.controls.fromdateCtrl.invalid && (wesForm.controls.fromdateCtrl.dirty || wesForm.controls.fromdateCtrl.touched)}"> 
      </div>

      <div class="col-md-3">To :</div>
      <div class="col-md-1">
      </div>
      <div class="col-md-6" >
        <input type="month" [(ngModel)]="todate" class="form-control" formControlName="todateCtrl"  [ngClass]="{'form-control-danger': wesForm.controls.todateCtrl.invalid && (wesForm.controls.todateCtrl.dirty || wesForm.controls.todateCtrl.touched)}"> 
      </div>


      <div class="col-md-3">Degree :</div>
      <div class="col-md-1">
      </div>
      <div class="col-md-6" >
        <input type="text" [(ngModel)]="degree" class="form-control" formControlName="degreeCtrl"  [ngClass]="{'form-control-danger': wesForm.controls.degreeCtrl.invalid && (wesForm.controls.degreeCtrl.dirty || wesForm.controls.degreeCtrl.touched)}"> 
      </div>


      <div class="col-md-3">Year of Award :</div>
      <div class="col-md-1">
      </div>
      <div class="col-md-6" >
        <input type="date" [(ngModel)]="yearaward" class="form-control" formControlName="yearawardCtrl"  [ngClass]="{'form-control-danger': wesForm.controls.yearawardCtrl.invalid && (wesForm.controls.yearawardCtrl.dirty || wesForm.controls.yearawardCtrl.touched)}"> 
      </div>


      <div class="col-md-3">Major :</div>
      <div class="col-md-1">
      </div>
      <div class="col-md-6" >
        <input type="text" [(ngModel)]="major" class="form-control" formControlName="majorCtrl"  [ngClass]="{'form-control-danger': wesForm.controls.majorCtrl.invalid && (wesForm.controls.majorCtrl.dirty || wesForm.controls.majorCtrl.touched)}"> 
      </div>



      <div class="col-md-3">Student RollNo :</div>
      <div class="col-md-1">
      </div>
      <div class="col-md-6" >
        <input type="text" [(ngModel)]="rollno" class="form-control" formControlName="rollnoCtrl"  [ngClass]="{'form-control-danger': wesForm.controls.rollnoCtrl.invalid && (wesForm.controls.rollnoCtrl.dirty || wesForm.controls.rollnoCtrl.touched)}"> 
      </div>

      <div class="col-md-3">Upload Signature :</div>
      <div class="col-md-1">
      </div>
      <div class="col-md-6" >
      <p-fileUpload  name="file" mode="basic" auto="true"
      [accept]="'.jpg,.jpeg,.png,.PNG,.JPEG,.JPG,.PDF,.pdf'" chooseLabel="Upload" 
      url="{{WesLetterUrl}}?hiddentype=Letter&user_id={{userId}}&appId={{appId}}"
      maxFileSize="5000000"
      (onBeforeSend)="onBeforeSend($event)" 
      (onUpload)="onUpload($event)"
      (onSelect)="onSelect($event)">
      </p-fileUpload>
      <nb-actions size="medium" fullWidth *ngIf="file_name != null" >
									<nb-action  style="color:rgb(30, 35, 107);font-size: 1.5rem;" class="control-item" class="ion ion-ios-eye" title="View File" (click)="imagePopup(file_name,file_type); ">
									</nb-action>
									<nb-action  style="color:rgb(206, 6, 6);font-size: 1.5rem;" *ngIf="moreDoc?.app_id == null" class="control-item" class="ion ion-ios-trash" title="Delete File"  (click)="deleteDocument(file_name?.doc_id,'marklist')">
									</nb-action>
			</nb-actions>
      </div>
      <div class="col-md-3">Student RollNo :</div>
      <div class="col-md-1">
      </div>
      <div class="col-md-6" >
      <button nbButton (click)="saveInstitution('')">Save</button>
            </div>
</div>
    </form>

</nb-card-body>


    
  </nb-card>


`,
})
export class AddWes_Form {
  @Input() institute : any;  
  @Input() function_type :string;
  @Input() info_type : string;
  @Input() save_type : string;
  @Input() bach_Data : string;
  @Input() degreeValue : string;
  WesLetterUrl = config.WesLetterUrl;
  otherField:boolean = false;
  data: any;
  old_email ; 
  user_id;
  SHOW:boolean;
  //NEW
  wesForm: FormGroup;
  readonly emailValidate = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  readonly mobileValidate = /^[0-9]\d{5,12}$/;
 
  msgs: any[];
  universityName;
  email;
  country:Number;
  contact;
  contactperson;
  universitynames : Observable<any[]>;
  EmailAutoS = [];
  country_nameAutoS = [];
  contact_personAutoS = [];
  contact_numberAutoS = [];
  Countries: any [];
  showList: boolean = true;
  wesTrue: boolean = false;
  cesTrue: boolean = false;
  eduPerTrue: boolean = false;
  iqasTrue: boolean = false;
  icasTrue: boolean = false;
  icesTrue: boolean = false;
  nasbaTrue : boolean = false;
  nceesTrue : boolean = false;
  wesno: any;
  epno: any;
  icasno: any;
  iqasno: any;
  cesno: any;
  studyTrue:boolean = false;
  visaTrue:boolean = false;
  employment:boolean= false;
  studyrefno:any;
  visarefno:any;
  emprefno:any;
  otheraccno: any;
  studyaccno: any;
  empaccno: any;
  visaaccno: any;
  otheraccTrue: boolean = false;
  myieeTrue: boolean = false;
  nceesno: any;
  naricTrue: boolean;
  naricno: any;
  show_edit_error: boolean=false;
  emailAsWes: any;
  ncaTrue: boolean;
  ncano: any;
  type: string = null;
  wesvalue: any;
  icasCtrl: any;
  iqasCtrl: any;
  cesCtrl: any;
  myieeCtrl: any;
  icesCtrl: any;
  nasbaCtrl: any;
  nceesCtrl: any;
  naricCtrl: any;
  ncaCtrl: any;
  hrdCtrl: any;
  nameaswes;
  name: any;
  surname: any;
  errflag: boolean=false;
  hrdTrue: boolean;
  hrdno: any;
  formdata: any;

  hrdData;
  course_name: any;
  full_name: any;
  prn_no: any;
  seat_no: any;
  seat_no_sem6: any;
  seat_no_sem5: any;
  cgpi: any;
  cgpa: any;
  transcript_no: any;
  transcript_date: any;
  exam_date: any;
  specialization: any;

  hrdData_master;
  course_name_master: any;
  full_name_master: any;
  prn_no_master: any;
  seat_no_master: any;
  seat_no_sem6_master: any;
  seat_no_sem5_master: any;
  cgpi_master: any;
  cgpa_master: any;
  transcript_no_master: any;
  transcript_date_master: any;
  exam_date_master: any;
  specialization_master: any;

  hrdData_phd;
  course_name_phd: any;
  full_name_phd: any;
  prn_no_phd: any;
  seat_no_phd: any;
  seat_no_sem6_phd: any;
  cgpi_phd: any;
  cgpa_phd: any;
  transcript_no_phd: any;
  transcript_date_phd: any;
  exam_date_phd: any;
  specialization_phd: any;


  @Input() editFlag : string;
  @Input() app_id : string;
  hrdinstituteData: any;
  hrdinstituteData_masters: any;
  hrdinstituteData_phd: any;
faculty: any;
patteren: any;
patteren_master: any;
patteren_phd: any;
secondlastsemvalue : any;
lastsemvalue : any;
address  :any;
state:any;
postalcode:any;
city:any;
dob:any;
institutionname:any;
fromdate:any;
todate:any;
degree:any;
yearaward:any;
major:any;
rollno:any;
position: string;
status: string;
currenttoken: NbAuthJWTToken;
wesform_Values;
file_name ;
file_type;
userId: string;
appId: string;
  constructor(protected ref: NbDialogRef<AddWes_Form>,
    protected api : ApiService,
    private fb: FormBuilder,
    private toasterService : NbToastrService,
    protected countries :CountriesService,
    private confirmationService: ConfirmationService,
    private authService: NbAuthService,
    private dialogService: NbDialogService,
    private route: ActivatedRoute,
  ) {
    this.Countries = this.countries.getData();
    this.api.suggestInstitute().subscribe((data)=>{
      this.universitynames = data['data'];
    })

    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        
        if (token.isValid()) {
          this.user_id = token.getPayload()['id']
           if (token.getPayload()['role'] == 'sub-admin') {
            //   this.role = token.getPayload()['role']
          }
        }

        this.api.getCollegeList().subscribe((data)=>{
          if(data['data'] != undefined){
            data['data'].forEach((element)=>{
            //   this.collegeList.push( element ) 
            })
          }
        })
       
      });
    
  }

  ngOnInit() {
    this.wesForm = this.fb.group({
         addressCtrl: ['',Validators.required],
         stateCtrl: ['',Validators.required],
         postalCtrl: ['',Validators.required],
         cityCtrl: ['',Validators.required],
         dobCtrl: ['',Validators.required],
         institutionnameCtrl: ['',Validators.required],
         fromdateCtrl: ['',Validators.required],
         todateCtrl: ['',Validators.required],
         degreeCtrl:['',Validators.required],
         majorCtrl :['',Validators.required],
         rollnoCtrl :['',Validators.required],
         yearawardCtrl :['',Validators.required]
         
       });



    this.api.suggestInstitute().subscribe((data)=>{
      this.universitynames = data['data'];
    })
  this.SHOW= true;
  this.userId = this.route.snapshot.queryParamMap.get('userId');
  this.appId = this.route.snapshot.queryParamMap.get('app_id');
  console.log("this.userId -->",this.userId);
  
  }


  dismiss() {
    this.ref.close();
  }

  saveInstitution(type){
  
    this.formdata = this.wesForm.value;
      if(this.wesForm){        
         this.wesForm.controls.addressCtrl.markAsDirty();
         this.wesForm.controls.stateCtrl.markAsDirty();
         this.wesForm.controls.postalCtrl.markAsDirty();
         this.wesForm.controls.cityCtrl.markAsDirty();
         this.wesForm.controls.dobCtrl.markAsDirty();
         this.wesForm.controls.institutionnameCtrl.markAsDirty();
         this.wesForm.controls.fromdateCtrl.markAsDirty();
         this.wesForm.controls.todateCtrl.markAsDirty();
         this.wesForm.controls.degreeCtrl.markAsDirty();
         this.wesForm.controls.majorCtrl.markAsDirty();
         this.wesForm.controls.rollnoCtrl.markAsDirty();
         this.wesForm.controls.yearawardCtrl.markAsDirty();
       }

     this.api.saveWes_FormDetails(this.formdata,this.userId).subscribe(data => {
        
        this.ref.close();
        this.address = data['data'].currentaddress
        this.state= data['data'].state
        this.postalcode= data['data'].postal_code
        this.city=data['data'].city
        this.dob=data['data'].dob
        this.institutionname=data['data'].institute_name
        this.fromdate=data['data'].datefrom
        this.todate=data['data'].dateto
        this.degree=data['data'].degree
        this.yearaward=data['data'].yearaward
        this.major=data['data'].major
        this.rollno=data['data'].sturolno
     })
    
    
  }
  imagePopup(imagename,imgType){
    
    
    this.dialogService.open(transcriptpreview, {
      context: {
      arr : imagename,
      //arr : 'https://mu.admissiondesk.org/uiserver/upload/transcript/20086/HywOaKlgI.pdf',
      imgType : imgType
      },
    });
  }

  onUpload(event) {    
    const reader = new FileReader();
    var duration = 10000;
    this.position = 'top-right';
    this.status = 'success';
    if(event.files && event.files.length) {
      const [file] = event.files;
      reader.readAsDataURL(file);
      var json = JSON.parse(event.xhr.response);
      
      var yourData = json.Data; // or json["Data"]     
      var yourStatus = json.status; // or json["Data"]
      var yourMessage = json.message;
      if (yourStatus == 200) {
        this.status = 'success';
        // this.loadingimg = false;
        this.toasterService.show(` ` + yourMessage,'SUCCESS', { duration} );

        this.api.getWesFormValues(this.userId).subscribe((data)=>{
          this.wesform_Values = data['data'];
          this.file_name = this.wesform_Values[0].file_name;
          this.file_type = this.wesform_Values[0].type; 
        })
       
        
      } else if (yourStatus == 401) {
        this.status = 'error';
      // this.loading = false;
        this.toasterService.show(
          ` ` + yourMessage,'SUCCESS', {
            duration
          }
        );
        
      } else if (yourStatus == 400) {
        this.status = 'error';
      // this.loading = false;
        this.toasterService.show(
          ` ` + yourMessage, 'SUCCESS', {
            duration
          }
        );
      }
      // this.loading = false;
      // this.ref.close(file);
    }
  
  }
  onBeforeSend(event) {
    // this.loadingimg = true;
    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
      if (token.isValid()) {
        this.currenttoken = token;
        event.xhr.setRequestHeader("Authorization", `Bearer ` + this.currenttoken);
      }
    });
  }
  onSelect($event: any): void {
    
    
    var maxFileSize =  5000000;
    var imgArr = $event.files[0].name.split('.');
    
    
    var extension = imgArr[imgArr.length - 1].trim();
    
    
    if ($event.files[0].size > maxFileSize) {
      this.confirmationService.confirm({
        message: 'Maximum file size should be 5 MB.',
        header: 'Invalid File Size',
        icon: 'pi pi-info-circle',
        rejectVisible : false,
        acceptLabel: 'Ok'
      });
    }
    
    if((extension!='jpg' && extension!='jpeg' && extension!='png' && extension!='JPG' && extension!='JPEG' && extension!='PNG' ) ) {
      this.confirmationService.confirm({
        message: 'Please upload your marksheet in .jpeg or .jpg or .png formats',
        header: 'Invalid File Type',
        icon: 'pi pi-info-circle',
        rejectVisible : false,
        acceptLabel: 'Ok'
      });
    }
    
    if((extension!='jpg' && extension!='jpeg' && extension!='png'  && extension!='JPG' && extension!='JPEG' && extension!='PNG')){
      this.confirmationService.confirm({
        message: 'Please upload your marksheet in .jpeg or .jpg or .png formats',
        header: 'Invalid File Type',
        icon: 'pi pi-info-circle',
        rejectVisible : false,
        acceptLabel: 'Ok'
      });
    }
    //this.loadingimg=true;
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode >= 48 && charCode <= 57) {
      return true;
    }
    return false;

  
  }



  onKey(event){

    if((event.target.value != undefined || event.target.value != null) && event.target.value.length > 0){

      this.showList = false
    }else{

      this.showList = true
    }
  }

  // @HostListener('document:keydown', ['$event'])
	// handleKeyboardEvent(event: KeyboardEvent) {
	//   if(event.key == 'Enter'){
	// 		if(this.institutionForm.valid  ){
				
  //       // this.saveInstitution()
  //       this.errflag=false;
	// 		}else{
  //      this.errflag=true
        
  //     }

  //  }
  // }

}
