import { Component, Input } from "@angular/core";
import { NbDialogRef } from "@nebular/theme";
import { Router } from "@angular/router";
import { ApiService } from "../../../shared/api.service";
import { NbToastrService } from "@nebular/theme";
import { NbAuthJWTToken, NbAuthService } from "@nebular/auth";

@Component({
  selector: "nb-dialog",
  template: `
    <nb-card
      [style.max-width.px]="1000"
      [style.max-height.px]="1000"
      status="primary"
    >
      <nb-card-header>
        <button nbButton (click)="close()" class="rotate-button">Close</button>
        <button
          nbButton
          *ngIf="imgType !== 'pdf'"
          (click)="rotate()"
          class="rotate-button"
        >
          Rotate
        </button>
      </nb-card-header>
      <nb-card-body [ngClass]="{ rotated: rotationAngle !== 0 }">
        <div
          *ngIf="imgType !== 'pdf'"
          style="overflow-y: auto; max-height: 90vh;"
        >
          <img
            [src]="arr"
            [style.transform]="getRotationStyle()"
            style="width: 100%; height: 800px; object-fit: cover;"
            alt="Image"
          />
        </div>

        <div class="pdf-container" *ngIf="imgType == 'pdf'">
          <pdf-viewer
            [src]="arr"
            [render-text]="true"
            style="display: block;"
          ></pdf-viewer>
        </div>
      </nb-card-body>
    </nb-card>
  `,
  styles: [
    `
      .rotated {
        transform-origin: center;
      }
      .rotate-button {
        margin-left: 10px;
        background-color: white;
        color: black;
      }
      .nb-card-header {
        justify-content: space-between;
        align-items: center;
      }
    `,
  ],
})
export class transcriptpreview {
  @Input() arr: any;
  @Input() imgType: any;
  user_id: any;
  rotationAngle: number = 0;
  rotationAngles: number[] = [0, 90, 180, 270];
  currentAngleIndex: number = 0;
  constructor(
    protected ref: NbDialogRef<transcriptpreview>,
    private router: Router,
    private authService: NbAuthService,
    protected api: ApiService,
    private toastrService: NbToastrService
  ) {
    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
      this.user_id = token.getPayload()["id"];
    });
    // <div ><img [src]="arr" height=":800px;" width="100%;" alt="Random first slide"></div>
  }
  close() {
    this.ref.close();
  }

  rotate() {
    this.currentAngleIndex =
      (this.currentAngleIndex + 1) % this.rotationAngles.length;
    this.rotationAngle = this.rotationAngles[this.currentAngleIndex];
  }

  getRotationStyle(): string {
    return `rotate(${this.rotationAngle}deg)`;
  }
}
