import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import {Observable, Subject, Subscriber} from 'rxjs/Rx';
import { NbAuthService } from '@nebular/auth';
import { NbThemeService } from '@nebular/theme';
import { config } from '../../../config';
import { applicationApi } from '../model/applicationApi';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  
  private baseUrl = config.serverUrl;
  constructor(private httpClient : HttpClient,
    public authService : NbAuthService,
    public themeService : NbThemeService) { }
    

    get(url: string): Observable<any> {
      return new Observable((observer: Subscriber<any>) => {
          let objectUrl: string = null;
          this.httpClient
              .get(url, {
                  responseType: 'blob'
              })
              .subscribe(m => {
                  objectUrl = URL.createObjectURL(m);
                  observer.next(objectUrl);
              });
  
          return () => {
              if (objectUrl) {
                  URL.revokeObjectURL(objectUrl);
                  objectUrl = null;
              }
          };
      });
  }

    login(email,password){
      try{
        return this.httpClient.post(`${this.baseUrl}/api/auth/login`,{"email":email,"password":password});
      }catch(error) {
        this.handleError("login from api : "+error);
      }
    }


    RegisterValues(data)
    {
      try{
          return this.httpClient.post(`${this.baseUrl}/api/auth/register`,{data : data});
      }catch(error){
        this.handleError('register errors')
      }
    }

    emailValues(data){
      try{
        
            return  this.httpClient.post(`${this.baseUrl}/api/auth/checkEmail`,{
              data : data
          
            });
       
      }catch(error){
        this.handleError("emailValues: "+JSON.stringify(error));
      }
    }


    ForgotPassword(email)
    {
      try{
          // return this.httpClient.post(`${this.baseUrl}/api/auth/register`,{data : data});
          return  this.httpClient.post(`${this.baseUrl}/api/auth/forgot-password`,{
                     data : email});
      }catch(error){
        this.handleError('register errors')
      }
    }

    resetPasswordValues(data){  
      try{  
        
            return  this.httpClient.post(`${this.baseUrl}/api/auth/resetpassword`,{
              data : data
            }); 
      }catch(error){
        this.handleError("resetPasswordValues: "+JSON.stringify(error));
      }
    }

    Otpvalue(data){
      try{
        return this.httpClient.post(`${this.baseUrl}/api/auth/verify-otp-reg`,{
          data : data
        });
      }catch(error) {
        this.handleError("Otpvalue : "+JSON.stringify(error));
      }
    }

  UpdateNumberOTP(data){
    try{
      return this.httpClient.post(`${this.baseUrl}/api/auth/resend-otp`,{
         data : data
      });
  }catch(error) {
    this.handleError("UpdateNumberOTP : "+JSON.stringify(error));
  }
  }

  confirmPass(email,pass){
          try{
            return this.httpClient.post(`${this.baseUrl}/mobile/user/confirm_api_password`,{"email":email,"pass":pass});
          }catch(error) {
            this.handleError("confirmPass : "+error);
          }
        }

          getCaptcha(){
    try{
        return  this.httpClient.get(`${this.baseUrl}/api/auth/captcha`);
            
    }catch(error) {
      this.handleError("getCaptcha: "+JSON.stringify(error));
    }
    
  }

//created by namrata
addNewCollegebyStudent(college){
  try{
    return  this.httpClient.post(`${this.baseUrl}/api/addNewCollegebyStudent`,college);     
  }catch(error){
    this.handleError('addNewCollegebyStudent: ' + JSON.stringify(error));
  }  
}

createInstitute(universityName,email,country,contactNumber,contact_person,type,address, landmark,pincode,wesNumber,iqasno,cesno,eduperno,icasno,studyrefno,emprefno,visarefno,studyaccno,empaccno,visaaccno,otheraccno,myieeno,icesno,nasbano,nceesno,naricno,ncano,nameaswes,lastnameaswes,emailAsWes){
  try{
   return  this.httpClient.post(`${this.baseUrl}/api/attestation/createInstitute`,{"universityName":universityName,"email":email,"country":country,"contactNumber":contactNumber,"contact_person":contact_person,"type":type,"address":address, "landmark":landmark,"pincode":pincode,
   "wesNumber":wesNumber,"iqasno":iqasno,"cesno":cesno,"eduperno":eduperno,"icasno":icasno,"studyrefno":studyrefno,"emprefno":emprefno,"visarefno":visarefno
   ,"studyaccno":studyaccno,"empaccno":empaccno,"visaaccno":visaaccno,"otheraccno":otheraccno,"myieeno":myieeno,"icesno":icesno,"nasbano":nasbano,"nceesno":nceesno,"naricno" : naricno,"ncano":ncano,"nameaswes":nameaswes,"lastnameaswes":lastnameaswes,"emailAsWes":emailAsWes });     
 }catch(error){
   this.handleError('createInstitute: ' + JSON.stringify(error));
 }
}


createInstituteWES(universityName,email,country,contactNumber,contact_person,type,address, landmark,pincode,wesNumber,iqasno,cesno,eduperno,icasno,studyrefno,emprefno,visarefno,studyaccno,empaccno,visaaccno,otheraccno,myieeno,icesno,nasbano,nceesno,naricno,ncano,nameaswes,lastnameaswes,emailAsWes,currentAddress,state,postalcode,date,institutionname,fromdate,todate,yearaward,major,sturollno,city){
  try{
   return  this.httpClient.post(`${this.baseUrl}/api/attestation/createInstitute_wes`,{"universityName":universityName,"email":email,"country":country,"contactNumber":contactNumber,"contact_person":contact_person,"type":type,"address":address, "landmark":landmark,"pincode":pincode,
   "wesNumber":wesNumber,"iqasno":iqasno,"cesno":cesno,"eduperno":eduperno,"icasno":icasno,"studyrefno":studyrefno,"emprefno":emprefno,"visarefno":visarefno
   ,"studyaccno":studyaccno,"empaccno":empaccno,"visaaccno":visaaccno,"otheraccno":otheraccno,"myieeno":myieeno,"icesno":icesno,"nasbano":nasbano,"nceesno":nceesno,"naricno" : naricno,"ncano":ncano,"nameaswes":nameaswes,"lastnameaswes":lastnameaswes,"emailAsWes":emailAsWes,"currentAddress" : currentAddress,"state" :  state ,"postalcode" : postalcode, "date" : date , "institutionname" :  institutionname ,"fromdate" : fromdate ,"todate" : todate , "yearaward" :yearaward , "major" :major ,"sturollno" : sturollno , "city" :city});     
 }catch(error){
   this.handleError('createInstitute: ' + JSON.stringify(error));
 }
}

suggestInstitute(){
  try{
    return  this.httpClient.get(`${this.baseUrl}/api/attestation/suggestInstitute`);
  }catch (error){
    this.handleError('suggestInstitute() : ' + JSON.stringify(error));
  }
}
getHrddata(editFlag,app_id){
  try{
    return  this.httpClient.get(`${this.baseUrl}/api/attestation/getHrddata?editFlag=${editFlag}&app_id=${app_id}`);
  }catch (error){
    this.handleError('getHrddata() : ' + JSON.stringify(error));
  }
}

suggestInstituteallDATA(universityName){
  try{
    return  this.httpClient.get(`${this.baseUrl}/api/attestation/suggestInstituteallDATA?universityName=`+universityName);
  }catch (error){
    this.handleError('suggestInstituteallDATA() : ' + JSON.stringify(error));
  }
}



updateInstituteData(id,universityName,email,country,contactNumber,contact_person,address,landmark,pincode,wesNumber,iqasno,cesno,eduperno,icasno,studyrefno,emprefno,visarefno,studyaccno,empaccno,visaaccno,otheraccno,myieeno,icesno,nasbano,nceesno,naricno,ncano,nameaswes,lastnameaswes,emailAsWes){
  try{
    return  this.httpClient.post(`${this.baseUrl}/api/attestation/updateInstituteData`,{"id":id,"universityName":universityName,"email":email,
    "country":country,"contactNumber":contactNumber,"contact_person":contact_person,"address":address, "landmark":landmark,
    "pincode":pincode,"wesNumber":wesNumber,"iaqsno":iqasno,"cesno":cesno,"eduperno":eduperno,"icasno" :icasno,"studyrefno":studyrefno,
    "emprefno":emprefno,"visarefno":visarefno,
    "studyaccno":studyaccno,"empaccno":empaccno,"visaaccno":visaaccno,"otheraccno":otheraccno,"mynieeno":myieeno,"icesno":icesno,"nasbano":nasbano,"nceesno" : nceesno,"naricno":naricno,"ncano" : ncano,"nameaswes":nameaswes,"lastnameaswes":lastnameaswes,"emailAsWes":emailAsWes});     
  }catch(error){
    this.handleError('updateInstituteData: ' + JSON.stringify(error));
  }
}
updateHrdInstituteData(data,type,sem1,sem2){
  try{
    return  this.httpClient.post(`${this.baseUrl}/api/attestation/updateHrdInstituteData`,{"data":data,"type" :type ,"sem1" : sem1 , "sem2" : sem2});
  }catch(error){
    this.handleError('updateHrdInstituteData: ' + JSON.stringify(error));
  }
}
saveWes_FormDetails(formdata,userId){
  try{
    return  this.httpClient.post(`${this.baseUrl}/api/attestation/saveWes_FormDetails`,{"formdata":formdata,"userId":userId});
  }catch(error){
    this.handleError('saveWes_FormDetails: ' + JSON.stringify(error));
  }
}



updateInstituteDataByAdmin(id,type,universityName,email,country,contactNumber,contact_person,address,landmark,pincode,wesNumber,iqasno,cesno,eduperno,icasno,studyrefno,emprefno,visarefno,studyaccno,empaccno,visaaccno,otheraccno,myieeno,icesno,nasbano,nceesno,naricno,ncano,nameaswes,lastnameaswes,emailAsWes){
  try{
    return  this.httpClient.post(`${this.baseUrl}/api/admin/updateInstituteDataByAdmin`,{"id":id,"universityName":universityName,"email":email,
    "country":country,"contactNumber":contactNumber,"contact_person":contact_person,"address":address, "landmark":landmark,
    "pincode":pincode,"wesNumber":wesNumber,"iaqsno":iqasno,"cesno":cesno,"eduperno":eduperno,"icasno" :icasno,"studyrefno":studyrefno,
    "emprefno":emprefno,"visarefno":visarefno,"type":type,
    "studyaccno":studyaccno,"empaccno":empaccno,"visaaccno":visaaccno,"otheraccno":otheraccno,"mynieeno":myieeno,"icesno":icesno,"nasbano":nasbano,"nceesno" : nceesno,"naricno":naricno,"ncano" : ncano,"nameaswes":nameaswes,"lastnameaswes":lastnameaswes,"emailAsWes":emailAsWes});     
  }catch(error){
    this.handleError('updateInstituteDataByAdmin: ' + JSON.stringify(error));
  }
}






deleteInstituteData(id){
  try{
    return  this.httpClient.post(`${this.baseUrl}/api/attestation/deleteInstituteData`,{"id":id});     
  }catch(error){
    this.handleError('deleteInstituteData: ' + JSON.stringify(error));
  }
}
onDeleteConfirmWesform(id){
  try{
    return  this.httpClient.post(`${this.baseUrl}/api/attestation/onDeleteConfirmWesform`,{"id":id});     
  }catch(error){
    this.handleError('onDeleteConfirmWesform: ' + JSON.stringify(error));
  }
}
deleteInstituteDatahrd(id){
  try{
    return  this.httpClient.post(`${this.baseUrl}/api/attestation/deleteInstituteDatahrd`,{"id":id});     
  }catch(error){
    this.handleError('deleteInstituteData: ' + JSON.stringify(error));
  }
}

getInstituteData(editFlag,app_id){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/getInstituteData?editFlag=${editFlag}&app_id=${app_id}`); 
  }catch(error){
    this.handleError('getInstituteData: ' + JSON.stringify(error));
  }
}
getHRDInstituteData(editFlag,app_id){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/getHRDInstituteData?editFlag=${editFlag}&app_id=${app_id}`);
  }catch(error){
    this.handleError('getHRDInstituteData: ' + JSON.stringify(error));
  }
}


getWes_FormData(editFlag,app_id){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/getWes_FormData?editFlag=${editFlag}&app_id=${app_id}`);
  }catch(error){
    this.handleError('getWes_FormData: ' + JSON.stringify(error));
  }
}



addToCart(){
  try{
    return  this.httpClient.get(`${this.baseUrl}/api/attestation/addToCart`);
  }catch(error){
    this.handleError('addToCart: ' + JSON.stringify(error));
  }
}

getCartData(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/getCartData`); 
  }catch(error){
    this.handleError('getCartData: ' + JSON.stringify(error));
  }
}

getUserData(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/getUserData`); 
  }catch(error){
    this.handleError('getUserData: ' + JSON.stringify(error));
  }
}

paymentrequest(total_amount){
  try{
    return  this.httpClient.post(`${this.baseUrl}/api/payment/paymentrequest`,{"total_amount":total_amount});     
  }catch(error){
    this.handleError('paymentrequest: ' + JSON.stringify(error));
  }
}

PaymentDetails(order_id){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/payment/PaymentDetails`,{"order_id":order_id});
  }catch(error) {
    this.handleError("PaymentDetails : "+error);
  }
}

OnlinePaymentChallan(transaction_id,payment_amount,payment_status,application_id,payment_date_time,user_id){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/payment/OnlinePaymentChallan`,{user_id:user_id,payment_amount:payment_amount,transaction_id:transaction_id,date_time:payment_date_time,status_payment:payment_status,application_id:application_id});
  }catch(error) {
    this.handleError("OnlinePaymentChallan : "+error);
  }
} 

downloadFiles(file_name):Observable<Blob>{
  try{
  let headers = new HttpHeaders();
  headers.append('Content-Type', 'application/json');
  return this.httpClient.get(`${this.baseUrl}/api/payment/download?file_name=`+file_name, { headers: headers, responseType: 'blob'}).map(
      (res) => {
          return new Blob([res], { type: 'application/pdf' });
      });
  }catch(error) {
    this.handleError("unable to get Files : "+JSON.stringify(error));
  }
}

downloadMarksheetFiles(file_name):Observable<Blob>{
  try{
  let headers = new HttpHeaders();
  headers.append('Content-Type', 'application/json');
  return this.httpClient.get(`${this.baseUrl}/api/attestation/downloadMarkshhet?file_name=`+file_name, { headers: headers, responseType: 'blob'}).map(
      (res) => {
          return new Blob([res], { type: 'application/pdf' });
      });
  }catch(error) {
    this.handleError("unable to get Files : "+JSON.stringify(error));
  }
}

My_pplication(){
  try{
    return  this.httpClient.get(`${this.baseUrl}/api/attestation/my_applications`);
  }catch (error){
    this.handleError('get my applications Details : ' + JSON.stringify(error));
  }
}

checkErrata(){
  try{
    return  this.httpClient.get(`${this.baseUrl}/api/attestation/checkErrata`);
  }catch (error){
    this.handleError('get my applications Details : ' + JSON.stringify(error));
  }
}


Details(){
  try{
    return  this.httpClient.get(`${this.baseUrl}/api/cart`);
  }catch (error){
    this.handleError('get cart Details : ' + JSON.stringify(error));
  }
}

async cartRemove(id,institute_id){
  try{
      return await this.httpClient.post(`${this.baseUrl}/api/removeCartvalue`,{'id' : id,'institute_id':institute_id});
    }catch(error){
      this.handleError("cartRemove : "+JSON.stringify(error));
  }
}

emailupdate(email,updated_email){
  try{
    return  this.httpClient.post(`${this.baseUrl}/api/emailUpdate`,{"email": email,"updated_email" : updated_email});
  }catch (error){
    this.handleError('emailupdate : ' + JSON.stringify(error));
  }
}


setEducationDetails(data){  
  try{  
    return  this.httpClient.post(`${this.baseUrl}/api/attestation/setEducationDetails`,{data : data}); 
  }catch(error){
    this.handleError("setEducationDetails: "+JSON.stringify(error));
  }
}

getEducationDetails(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/getEducationDetails`);
  }catch(error) {
      this.handleError("getEducationDetails : "+JSON.stringify(error));
  }
}

getDocumentDetails(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/getDocumentDetails`);
  }catch(error) {
      this.handleError("getDocumentDetails : "+JSON.stringify(error));
  }
}

getDocumentStatus(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/getDocumentStatus`);
  }catch(error) {
      this.handleError("getDocumentStatus : "+JSON.stringify(error));
  }
}

getInstituteStatus(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/getInstituteStatus`);
  }catch(error) {
      this.handleError("getDocumentStatus : "+JSON.stringify(error));
  }
}

getApplyingEducation(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/getApplyingEducation`);
  }catch(error) {
      this.handleError("getApplyingEducation : "+JSON.stringify(error));
  }
}

checkCart(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/checkCart`);
  }catch(error) {
      this.handleError("checkCart : "+JSON.stringify(error));
  }
}
checkDegree(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/checkDegree`);
  }catch(error) {
      this.handleError("checkDegree : "+JSON.stringify(error));
  }
}

getProfileValues(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/dashboard/profileValue`);
}catch(error) {
    this.handleError("getProfileValues : "+JSON.stringify(error));
}

}

getSendUniversityMail(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/admin/getapptrackerdetails`,{});
  }catch(error) {
    this.handleError("login from api : "+error);
  }
}

getAllSendMail(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/admin/getapplicationMailsDetails`,{});
  }catch(error) {
    this.handleError("login from api : "+error);
  }
}

updateProfileValues(data){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/dashboard/updateProfile`,{
      data : data
    });
}catch(error) {
    this.handleError("updateProfileValues : "+JSON.stringify(error));
}

}

// admin-dashboard 

sendOtp(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/get_otp`);
  }catch(error) {
      this.handleError("sendOtp : "+JSON.stringify(error));
  }
}
  updateOtp(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/update_otp`);
  }catch(error) {
      this.handleError("updateOtp : "+JSON.stringify(error));
  }
}

getTranscriptDetails(user_id){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/getTranscriptDetails?user_id=${user_id}`);
  }catch(error) {
      this.handleError("getTranscriptDetails : "+JSON.stringify(error));
  }
}

getinvoicedetails(details){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/payment/getinvoicedetails?details=${details}`);
  }catch(error) {
      this.handleError("getQuickInvoice : "+JSON.stringify(error));
    }
}
downloadFilesAdmin(file_name,user_id):Observable<Blob>{
  try{
  let headers = new HttpHeaders();
  headers.append('Content-Type', 'application/json');
  return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/download?file_name=${file_name}&user_id=${user_id}`, { headers: headers, responseType: 'blob'}).map(
      (res) => {
          return new Blob([res], { type: 'application/pdf' });
      });
  }catch(error) {
    this.handleError("unable to get Files : "+JSON.stringify(error));
  }
}

getTotalApplications(page,id,name,email,year){
  try{
  const requestUrl = `${this.baseUrl}/api/admin/adminDashboard/total?page=${page}&id=${id}&name=${name}&email=${email}&acadYear=${year}`;
    return this.httpClient.get(requestUrl);
  }catch(error){
    this.handleError("getTotalApplications : "+JSON.stringify(error));
  }
  }
getTotalApplications_forFinance(page,id,name,email,year){
  try{
  const requestUrl = `${this.baseUrl}/api/admin/adminDashboard/totalforfinance?page=${page}&id=${id}&name=${name}&email=${email}&acadYear=${year}`;
    return this.httpClient.get(requestUrl);
  }catch(error){
    this.handleError("getTotalApplications_forFinance : "+JSON.stringify(error));
  }
  }
  
  getPendingApplications(page,id,name,email,tabChange){
    try{
    const requestUrl = `${this.baseUrl}/api/admin/adminDashboard/unsigned?page=${page}&id=${id}&name=${name}&email=${email}&tab=${tabChange}`;
      return this.httpClient.get(requestUrl);
    }catch(error){
      this.handleError("getPendingApplications : "+JSON.stringify(error));
    }
    }
  
    getVerifiedApplications(page,applicationId,value){      
      try{
      const requestUrl = `${this.baseUrl}/api/admin/adminDashboard/verified?page=${page}&applicationId=${applicationId}&value=${value}`;
      return this.httpClient.get(requestUrl);
    }catch(error){
      this.handleError("getVerifiedApplications : "+JSON.stringify(error));
    }
    }

    getSignedApplications(value){
      try{
      const requestUrl = `${this.baseUrl}/api/admin/adminDashboard/signed?value=${value}`;
      return this.httpClient.get(requestUrl);
    }catch(error){
      this.handleError("getVerifiedApplications : "+JSON.stringify(error));
    }
    }

    
    getEmailedApplications(page,id,name,email,year) {
      try{
        const requestUrl = `${this.baseUrl}/api/admin/adminDashboard/emailed?page=${page}&id=${id}&name=${name}&email=${email}&acadYear=${year}`;
        return this.httpClient.get(requestUrl);
      }catch(error){
        this.handleError("getVerifiedApplications : "+JSON.stringify(error));
      }
    }

    getWesFormValues(userId) {
      try{
        const requestUrl = `${this.baseUrl}/api/admin/adminDashboard/getWesFormValues?userId=${userId}`;
        return this.httpClient.get(requestUrl);
      }catch(error){
        this.handleError("getWesFormValues : "+JSON.stringify(error));
      }
    }

    updateVerifiedBy(email, id){
      try{
        return this.httpClient.post(`${this.baseUrl}/api/admin/adminDashboard/pending/verifiedBy`,{"email":email,"id":id});
      }catch(error) {
        this.handleError("login from api : "+error);
      }
    }

    setPaymentIssueDetails(data,id){
      try{
        return this.httpClient.post(`${this.baseUrl}/api/attestation/setPaymentIssueDetails`,{"data":data,"id":id,});
      }catch(error) {
        this.handleError("setPaymentIssueDetails : "+error);
      }
    }

    // sendEmail(id){
    //   try{
    //     return this.httpClient.post(`${this.baseUrl}/api/admin/adminDashboard/sendEmail`,{"id":id});
    //   }catch(error) {
    //     this.handleError("login from api : "+error);
    //   }
    // }
    
    getSigned_namrata_Applications(sort: string, order: string, page: number,per_page : number): Observable<applicationApi> {
      try{
      const requestUrl = `${this.baseUrl}/api/admin/adminDashboard/signed_namrata?sort=${sort}&order=${order}&page=${page + 1}&per_page=${per_page}`;
      return this.httpClient.get<applicationApi>(requestUrl);
    }catch(error){
      this.handleError("getVerifiedApplications : "+JSON.stringify(error));
    }
    }

    getEmailActivityTracker(){
      try{
        return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/getEmailActivityTracker`);
      }catch(error) {
        this.handleError("getEmailActivityTracker : "+JSON.stringify(error));
      }
    }
    geterror_msg(value){      
      try{
        return this.httpClient.get(`${this.baseUrl}/api/attestation/geterror_msg?value=`+value);
      }catch(error) {
        this.handleError("geterror_msg : "+JSON.stringify(error));
      }
    }


    getPaymentIssueDetails(){
      try{
        return this.httpClient.get(`${this.baseUrl}/api/attestation/getPaymentIssueDetails`);
      }catch(error) {
        this.handleError("getPaymentIssueDetails : "+JSON.stringify(error));
      }
    }

    getEmailActivityTrackerMonthWise(params){
      try{
        return this.httpClient.post(`${this.baseUrl}/api/admin/adminDashboard/getEmailActivityTrackerMonthWise`,{
          yearMonth : params,          
        });
      } catch(error) {
          this.handleError("getEmailActivityTrackerMonthWise : "+JSON.stringify(error));
      }
    }

    async getAllTranscriptData(userId,app_id){
      try{
        return await this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/transcript_lock?userId=`+userId+"&app_id="+app_id).toPromise();
      }catch(error) {
        this.handleError("Error in getAllTranscriptData : "+error);
      }
    }

    
downloadTranscript(file):Observable<Blob>{

  try {
    let headers = new HttpHeaders();
    headers.append('Content-Type','application/json');
      return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/downloadTranscript?file=${file}`, { headers: headers, responseType: 'blob'}).map(
        (res) => {
          return new Blob([res], { type: 'application/pdf' });
        });

  }
   catch (error) {
    this.handleError("unable to get the Files :"+JSON.stringify(error))
  }
  }

    
        //////////CREATED BY PRIYANKA
        getStatuswithid(Appc_id){
          try{ 
                return  this.httpClient.post(`${this.baseUrl}/api/dashboard/appTrackerwithid`,{"app_id": Appc_id});     
           }catch (error){ 
            this.handleError('getStatus: ' + JSON.stringify(error));
          }
          
         }

         getPurposewithid(User_id){
         
          try{ 
                return  this.httpClient.post(`${this.baseUrl}/api/dashboard/purposeTrackerwithid`,{"user_id": User_id});     
           }catch (error){ 
            this.handleError('getStatus: ' + JSON.stringify(error));
          }
          
         }
         
         getStatus(){
          try{
                return  this.httpClient.get(`${this.baseUrl}/api/dashboard/appTracker`);     
           }catch (error){ 
            this.handleError('getStatus: ' + JSON.stringify(error));
          }
          
         }
    
        getApplicationdetails(): any {
          try{
            return this.httpClient.get(`${this.baseUrl}/api/dashboard/getApplicationDetails`);
          }catch(error){
            this.handleError("get application details: "+error);
          }
        }
    
        getpreAppldetails(): any {
          try{
            return this.httpClient.get(`${this.baseUrl}/api/dashboard/getpreAppldetails`);
          }catch(error){
            this.handleError("get application details: "+error);
          }
        }

        getErrataUpdate():any{
          try{
            return this.httpClient.get(`${this.baseUrl}/api/dashboard/getErrataUpdate`);
          }catch(error){
            this.handleError("get application details: "+error);
          }
        }


        
language(language:any,userId:any){
  try{
    return  this.httpClient.post(`${this.baseUrl}/api/attestation/language`,{"language":language,"userId":userId});     
  }catch(error){
    this.handleError('language: ' + JSON.stringify(error));
  } 
}


// signpdf(appl_id: any,type : any) {
//   try{
//     return this.httpClient.post(`${this.baseUrl}/api/signpdf/signpdf`,{"appl_id":appl_id,"type":type})
//   }catch(error) {
//     this.handleError("getEmailActivityTracker : "+JSON.stringify(error));
//   }
// }

signpdf(appl_id: any,type : any,degree:string ,wessigning : string) {
  try{
    return this.httpClient.post(`${this.baseUrl}/api/signpdf/documentSigning`,{"appl_id":appl_id,"type":type,"degree":degree,"wessigning" :wessigning})
  }catch(error) {
    this.handleError("signpdf : "+JSON.stringify(error));
  }
}
getwesdetails(wesno : any ,email : any ,name : any , lastname : any) {
  try{
    return this.httpClient.post(`${this.baseUrl}/api/signpdf/getWes_details`,{"wesno":wesno , "email" : email , "name" : name , "lastname" : lastname})
  }catch(error) {
    this.handleError("getwesdetails : "+JSON.stringify(error));
  }
}
        

         /**
   * Adds notifications to the Database
   * @author Rafique Shaikh
   */

  // socketmessage = new Subject();
  // socketNotificationNo = new Subject();
  private notification_no : any;
private messages = [];

  sendNotifications(appId,type){
    try {
      return this.httpClient.post(`${this.baseUrl}/api/admin/adminDashboard/sendNotification`, {"id":appId,"type":type});
    } catch (error) {
      this.handleError("sendNotifications: "+JSON.stringify(error));
    }
  }

  receiveNotifications(userId){
   return this.httpClient.post(`${this.baseUrl}/api/admin/adminDashboard/receiveNotification`, {"id":userId}).subscribe(notifications=>{
      this.messages = notifications['data'];
      this.notification_no = notifications['notification_no'];
      if(notifications['data'].length == 0){
        //this.socketNotificationNo.next('');
        //this.socketmessage.next('');
      }else if(notifications['data'].length > 0){
        //this.socketNotificationNo.next(this.notification_no);
        //this.socketmessage.next(this.messages);
      }
    },error => {
      this.handleError("receiveNotifications: "+JSON.stringify(error));
      });
  }

  reloadnotification(userId){
   try{
      return this.httpClient.post(`${this.baseUrl}/api/admin/adminDashboard/receiveNotification`,{"id":userId});
    }catch(error) {
      this.handleError("notification : "+error);
    }
  }

  makeReadNotification(userId){
    try{
      return this.httpClient.post(`${this.baseUrl}/api/admin/adminDashboard/makeReadNotification`,{"id":userId});
    }catch(error) {
      this.handleError("makeReadNotification : "+error);
    }
  }
  
  checkWesStatus(user_id){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/wes/checkStats?userId=`+user_id);
     }catch(error) {
       this.handleError("checkStats: "+JSON.stringify(error));
     } 
  }
  

  deleteNotification(userId,noti_id){
    try{
      return this.httpClient.post(`${this.baseUrl}/api/admin/adminDashboard/deleteNotification`,{"id":userId,"noti_id":noti_id});
    }catch(error) {
      this.handleError("deleteNotification : "+error);
    }
  }

  getTheme(){
    try{
      return  this.httpClient.get(`${this.baseUrl}/api/dashboard/getTheme`);
    }catch(error) {
      this.handleError("getTheme : "+error);
    }
  }

 /**
   * Adds notifications to the Database for errata
   * @author Priyanka khandagale
   */
 sendmessage(msg_from_admin_data,marksheet,transcript,app_id){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/admin/adminDashboard/trans_sendmessage`,{"msg":msg_from_admin_data,"marksheetcheck":marksheet,"transcriptchcek":transcript ,"app_id" : app_id });
  }catch(error) {
    this.handleError("ERROR sendmessage : "+error);
  }
}

createMerged(app_id ,user_id){
      try{
        return  this.httpClient.get(`${this.baseUrl}/api/dashboard/createMerged?app_id=`+app_id+"&user_id="+user_id);
        }catch (error){ 
          this.handleError('createMerged : ' + JSON.stringify(error));
        }
}


  checkapplications(){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/attestation/checkapplications`);
    }catch(error) {
      this.handleError("checkapplications : "+error);
    }
  }

  getAllPayments(){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/payment/getAllPayments`);     
    }catch(error){
      this.handleError('getAllPayments: ' + JSON.stringify(error));
    }
  }

  getApplWisePayments(appl_id){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/payment/getApplWisePayments?appl_id=${appl_id}`);     
    }catch(error){
      this.handleError('getAllPayments: ' + JSON.stringify(error));
    }
  }

  downloadPaymentExcel(pdf):Observable<Blob>{
    try{
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      return this.httpClient.get(`${this.baseUrl}/api/payment/downloadExcel?pdf=`+pdf, { headers: headers, responseType: 'blob'}).map(
        (res) => {
            return new Blob([res], { type: 'application/pdf' });
        });
    }catch(error) {
      this.handleError("downloadPaymentExcel unable to get Files : "+JSON.stringify(error));
    }
  }


  splitExcel(){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/payment/splitExcel`);
    }catch(error) {
      this.handleError("splitExcel : "+JSON.stringify(error));
    }
  }

  downloadReceipt(id):Observable<Blob>{
    try{
      let headers = new HttpHeaders();
      headers.append('Content-Type','application/json');
        return this.httpClient.get(`${this.baseUrl}/api/attestation/downloadPaymentReceipt?appl_id=`+id, { headers: headers, responseType: 'blob'}).map(
          (res) => {
            return new Blob([res], { type: 'application/pdf' });
          });
     }catch(error){
      this.handleError("Error downloadpaymentReceipt: "+JSON.stringify(error));
    }
  }

  getSignedPdfDetails(appl_id,user_id) {
    try{
      return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/getSignedPdfDetails?user_id=${user_id}&appl_id=${appl_id}`);
    }catch(error) {
        this.handleError("getSignedPdfDetails : "+JSON.stringify(error));
    }
  }
  
  downloadFilesAdmin_signedpdf(file_name):Observable<Blob>{
    try{
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/downloadSignedpdf?file_name=${file_name}`, { headers: headers, responseType: 'blob'}).map(
        (res) => {
            return new Blob([res], { type: 'application/pdf' });
        });
    }catch(error) {
      this.handleError("unable to get Files : "+JSON.stringify(error));
    }
  }

  checkTransStatus(user_id,app_id) {
    try{
      return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/getAppTransStatus?user_id=${user_id}&app_id=${app_id}`)
    }catch(error){
      this.handleError("checkTransStatus: "+JSON.stringify(error));
    }
  }

  //NEW
getBoard(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/getBoard`);
  }catch(error) {
      this.handleError("getBoard : "+JSON.stringify(error));
  }
}

saveBoard(board_id,user_id){
  try{
    return  this.httpClient.post(`${this.baseUrl}/api/attestation/saveBoard`,{"board_id" : board_id, "user_id" : user_id});
  }catch(error) {
      this.handleError("saveBoard : "+JSON.stringify(error));
  }
}

getUserWiseBoard(){
  try{
    return  this.httpClient.get(`${this.baseUrl}/api/attestation/getUserWiseBoard`);
  }catch(error) {
      this.handleError("getUserWiseBoard : "+JSON.stringify(error));
  }
}

//Get IP Adress using http://freegeoip.net/json/?callback
getIpAddress() {
  return this.httpClient
    .get('https://ipapi.co/json/')
    // .get('http://ip-api.com/json')
    .map(response => response || {})
    .catch(this.handleError1);
}

getCollegeList(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/getCollegeList`); 
  }catch(error){
    this.handleError('getCollegeList: ' + JSON.stringify(error));
  }
}

getCourse(app_id,user_id){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/getCourse?appId=${app_id}&userId=${user_id}`); 
  }catch(error){
    this.handleError('getCollegeList: ' + JSON.stringify(error));
  }
}
getFacultyList(degreeName){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/getFacultyList?degree=${degreeName}`); 
  }catch(error){
    this.handleError('getFacultyList: ' + JSON.stringify(error));
  }
}

getTranscript_name(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/dashboard/getTranscript_name`,);
  }catch(error) {
      this.handleError("getTranscript_name : "+JSON.stringify(error));
  }
}

getcheckstepper(editFlag,app_id){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/dashboard/checkStepper?editFlag=${editFlag}&app_id=${app_id}`);
  }catch(error) {
      this.handleError("getTranscript_name : "+JSON.stringify(error));
  }
}


getallstudents(page,name,email){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/students?page=${page}&name=${name}&email=${email}`);
    
  }catch(error) {
      this.handleError("getallstudents : "+JSON.stringify(error));
    }
}

getCountryWiseApplication(year,country_name,applying_for){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/getCountryWiseApplication?year=`+year+'&country_name='+country_name+'&applying_for='+applying_for);
  }catch(error) {
      this.handleError("getallstudents : "+JSON.stringify(error));
    }
}

status(status,userId){
  try{
    return this.httpClient.put(`${this.baseUrl}/api/admin/adminDashboard/status`,{"status":status,"userId": userId});
  }catch(error) {
    this.handleError("status : "+JSON.stringify(error));
  }
}

getApplWisePaymentsAdmin(appl_id,user_id){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/getApplWisePayments?appl_id=`+appl_id+`&id=`+user_id);   
  }catch(error){
    this.handleError('getAllPayments: ' + JSON.stringify(error));
  }
}

downloadReceiptAdmin(id,user_id):Observable<Blob>{
  try{
    let headers = new HttpHeaders();
    headers.append('Content-Type','application/json');
      return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/downloadPaymentReceipt?appl_id=`+id+`&user_id=`+user_id, { headers: headers, responseType: 'blob'}).map(
        (res) => {
          return new Blob([res], { type: 'application/pdf' });
        });
   }catch(error){
    this.handleError("Error downloadpaymentReceipt: "+JSON.stringify(error));
  }
}

view_Application(id){
  try{
    return  this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/my_applications?id=`+id);
  }catch (error){
    this.handleError('get my applications Details : ' + JSON.stringify(error));
  }
}

getPaymentDetails(tab_type){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/payment/getPaymentDetails?tab_type=`+tab_type);
  }catch(error) {
    this.handleError("getPaymentDetails : "+JSON.stringify(error));
  }
}

proceedSplit(order_id,edu_share,uni_share,ccavenue_share,reference_no){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/payment/proceedSplit`,{"order_id":order_id,"edu_share":edu_share,"uni_share":uni_share,"ccavenue_share":ccavenue_share,"reference_no":reference_no});
  }catch(error) {
    this.handleError("proceed2ndSplit : "+JSON.stringify(error));
  }
}

proceedRefund(order_no,order_id,edu_refund,uni_refund,cc_refund,reference_no){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/payment/proceedRefund`,{"order_no":order_no,"order_id":order_id,"edu_refund":edu_refund,"uni_refund":uni_refund,"cc_refund":cc_refund,"reference_no":reference_no});
  }catch(error) {
    this.handleError("proceedRefund : "+JSON.stringify(error));
  }
}

changeSplitPayoutStatus(order_no,order_id,reference_no){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/payment/changeSplitPayoutStatus`,{"order_no":order_no,"order_id":order_id,"reference_no":reference_no});
  }catch(error) {
    this.handleError("changeSplitPayoutStatus : "+JSON.stringify(error));
  }
}

feedBack(satisfy, recommend, staff, experience, exp_prob, suggestion){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/attestation/feedBack`,{"satisfy":satisfy, "recommend":recommend, "staff":staff, "experience":experience, "exp_prob":exp_prob, "suggestion":suggestion});
  }catch(error) {
    this.handleError("feedBack : "+error);
  }
}

activitytracker(page,date,data,email){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/activitytracker?page=${page}&date=${date}&data=${data}&email=${email}`);
  }catch(error) {
    this.handleError("activitytracker : "+JSON.stringify(error));
  }
}

downloadExcel(type){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/downloadExcel?type=`+type)
  }catch(error){
    this.handleError(" downloadExcel : "+JSON.stringify(error))
  }
}

downloadExcel_datewise(type,startDate,endDate){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/downloadExcel_datewise?type=`+type+ '&startDate=' + startDate+ '&endDate=' + endDate)
  }catch(error){
    this.handleError(" downloadExcel : "+JSON.stringify(error))
  }
}
downloadExcelWes(startDate,endDate){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/downloadExcelWes?startDate=` + startDate+ '&endDate=' + endDate)
  }catch(error){
    this.handleError(" downloadExcel : "+JSON.stringify(error))
  }
}
downloadFilesExcel(file_path):Observable<Blob>{
  try{
  let headers = new HttpHeaders();
  headers.append('Content-Type', 'application/json');
  return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/downloadfile?file_path=`+file_path, { headers: headers, responseType: 'blob'}).map(
      (res) => {
          return new Blob([res], { type: 'application/pdf' });
      });
  }catch(error) {
    this.handleError("unable to get Files : "+JSON.stringify(error));
  }
}

getMenuRole(userID){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/admin/role_management/getMenuRole?userID=`+userID);
  }catch(error){
    this.handleError(" getMenuRole : "+JSON.stringify(error));
  }
}

generateInvoice(data){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/payment/getQuickInvoice`,{"data":data});
  }catch(error) {
      this.handleError("getQuickInvoice : "+JSON.stringify(error));
    }
}
getallstudentfeedback(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/admin/student_feeback`);
  }catch(error) {
    this.handleError("getallstudentfeedback : "+JSON.stringify(error));
  }
}

getallsubadmin(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/admin/role_management/main`);
  }catch(error) {
      this.handleError("getallsubadmin : "+JSON.stringify(error));
    }
}

addupdateRole(roles,user_id){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/admin/role_management/setUpdateRole`,{"roles":roles,"user_id":user_id});
  }catch(error) {
      this.handleError("addupdateRole : "+JSON.stringify(error));
    }
}

getRolesData(userId){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/admin/role_management/getRolesData?userId=`+userId);
  }catch(error) {
      this.handleError("getRolesData : "+JSON.stringify(error));
    }
}

getSubAdminData(userId){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/admin/role_management/getSubAdminData?userId=`+userId);
  }catch(error) {
    this.handleError("getSubAdminData : "+JSON.stringify(error));
  }
}

addUpdatesubAdmin(userId, subAdminData){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/admin/role_management/addUpdatesubAdmin`,{"userId" : userId, "subAdminData" : subAdminData});
  }catch(error) {
    this.handleError("addUpdatesubAdmin : "+JSON.stringify(error));
  }
}

changeSubAdminStatus(userId){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/admin/role_management/changeSubAdminStatus`,{"userId" : userId});
  }catch(error) {
    this.handleError("changeSubAdminStatus : "+JSON.stringify(error));
  }
}


getPassword(app_id, docID , location,email,fileName){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/admin/getclickDetails`,{"app_id" :app_id , "docID": docID , "location" : location, "email": email, "fileName" : fileName});
  }catch(error) {
    this.handleError("getRolesData : "+JSON.stringify(error));
  }
}

getapplWiseTracker(Appc_id,user_id,email){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/admin/getapplWiseTracker`,{"app_id" :Appc_id , "user_id" : user_id, "email": email});
  }catch(error) {
    this.handleError("getRolesData : "+JSON.stringify(error));
  }
}

downloadStructureFile(filePath,app_id):Observable<Blob>{
  try{
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
  return this.httpClient.get(`${this.baseUrl}/api/auth/downloadStructureFile?file_name=`+filePath+`&app_id=`+app_id, {headers: headers, responseType: 'blob'}).map(
      (res) => {
          return new Blob([res], { type: 'application/pdf' });
      });
  }catch(error) {
    this.handleError("unable to get Files : "+JSON.stringify(error));
  }
}


addUserDegree(degree){ 
  try{
    return this.httpClient.post(`${this.baseUrl}/api/attestation/addUserDegree`,{"degree" :degree});
  }catch(error) {
    this.handleError("addUserDegree: "+JSON.stringify(error));
  }
}

getUploadUserMarklist(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/getUploadUserMarklist`);
  }catch(error) {
    this.handleError("getUploadUserMarklist: "+JSON.stringify(error));
  }
}

viewFileYearWise(year,pattern,education){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/viewFileYearWise?year=`+year+`&pattern=`+pattern+'&education='+education);
  }catch(error) {
    this.handleError("viewFileYearWise: "+JSON.stringify(error));
  }
}

getMarklistLetter(id){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/getMarklistLetter?id=`+id)
  }catch(error) {
    this.handleError("getMarklistLetter  : "+JSON.stringify(error));
  }
}

getCurriculum(id){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/getCurriculum?id=`+id)
  }catch(error) {
    this.handleError("getCurriculum  : "+JSON.stringify(error));
  }
}

getUserEducation(editFlag,app_id){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/getUserEducation?editFlag=${editFlag}&app_id=${app_id}`);
  }catch(error) {
    this.handleError("getUserEducation: "+JSON.stringify(error));
  }
}

deleteTranscript(userData){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/dashboard/deleteTranscripts`,{'data':userData});
  }catch(error) {
    this.handleError("getUploadTranscript: "+JSON.stringify(error));
  }
}


deleteUploadFile(id){
  try{      
      return this.httpClient.delete(`${this.baseUrl}/api/attestation/deleteUploadFile?id=`+id);
          
  }catch(error) {
    this.handleError("deleteUploadFile: "+JSON.stringify(error));
  }
}



getPurposeDetails(userId,app_id){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/getPurposeDetails1?userId=`+ userId + '&app_id=' + app_id);
  }catch(error) {;
    this.handleError("Error in getPurposeDetails : "+error);
  }
}

resetApplicationStage(tracker,app_id){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/admin/adminDashboard/resetApplicationStage`,{'tracker' : tracker,'app_id' : app_id});
  }catch(error) {;
    this.handleError("Error in resetApplicationStage : "+error);
  }
}

getUploadedTranscript(degreeValue, editFlag, app_id){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/dashboard/getUploadedTranscripts?degreeValue=${degreeValue}&editFlag=${editFlag}&app_id=${app_id}`,);
  }catch(error) {
      this.handleError("getUploadedTranscripts : "+JSON.stringify(error));
  }
}
getuserEducationDetails(degreeValue,editFlag,app_id){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/getuserEducationDetails?degreeValue=${degreeValue}&editFlag=${editFlag}&app_id=${app_id}`);
  }catch(error) {
    this.handleError("getuserEducationDetails: "+JSON.stringify(error));
  }
}

getUserMasterDetails(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/getUserMasterDetails`);
  }catch(error) {
    this.handleError("getUserMasterDetails: "+JSON.stringify(error));
  }
}
getUserPhdDetails(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/getUserPhdDetails`);
  }catch(error) {
    this.handleError("getUserPhdDetails: "+JSON.stringify(error));
  }
}
getUploadedMarklist(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/getUploadedMarklist`,);
  }catch(error) {
      this.handleError("getUploadedMarklist : "+JSON.stringify(error));
  }
}

getUploadedMasterMarklist(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/getUploadedMasterMarklist`,);
  }catch(error) {
      this.handleError("getUploadedMasterMarklist : "+JSON.stringify(error));
  }
}
getUploadedPhdMarklist(){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/getUploadedPhdMarklist`,);
  }catch(error) {
      this.handleError("getUploadedPhdMarklist : "+JSON.stringify(error));
  }
}
deleteMarklist(userData){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/attestation/deleteMarklist`,{'data':userData});
  }catch(error) {
    this.handleError("getUploadUserMarklist: "+JSON.stringify(error));
  }
}

deleteErrataMarklist(userData){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/attestation/deleteErrataMarklist`,{'data':userData});
  }catch(error) {
    this.handleError("deleteErrataMarklist: "+JSON.stringify(error));
  }
}

deleteErrataTranscript(userData){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/dashboard/deleteErrataTranscript`,{'data':userData});
  }catch(error) {
    this.handleError("deleteErrataTranscript: "+JSON.stringify(error));
  }
}

deleteErrataNamechange(userData){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/dashboard/deleteErrataNamechangeletter`,{'data':userData});
  }catch(error) {
    this.handleError("deleteErrataNamechange: "+JSON.stringify(error));
  }
}
deleteErrataCurriculum(userData){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/attestation/deleteErrataCurriculum`,{'data':userData});
  }catch(error) {
    this.handleError("deleteErrataCurriculum: "+JSON.stringify(error));
  }
}



changeCollegeWithDeleteMarklist(year,pattern,education){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/changeCollegeWithDeleteMarklist?year=`+year+`&pattern=`+pattern +`&education=`+education);
  }catch(error) {
    this.handleError("changeCollegeWithDeleteMarklist: "+JSON.stringify(error));
  }
}

checksignedpdf(appl_id,user_id) {
  try{
    return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/checksignedpdf?user_id=${user_id}&appl_id=${appl_id}`);
  }catch(error) {
      this.handleError("checksignedpdf : "+JSON.stringify(error));
  }
}

resendApp(appl_id,user_id) {
  try{
    return this.httpClient.post(`${this.baseUrl}/api/admin/adminDashboard/resendApp`,{'user_id':user_id,'appl_id':appl_id});
  }catch(error) {
      this.handleError("resendApp : "+JSON.stringify(error));
  }
}

resendAppToPending(appl_id,user_id , value) {
  try{
    return this.httpClient.post(`${this.baseUrl}/api/admin/adminDashboard/resendAppToPending`,{'user_id':user_id,'appl_id':appl_id , 'value' : value});
  }catch(error) {
      this.handleError("resendAppToPending : "+JSON.stringify(error));
  }
}

removefromreject(appl_id,user_id) {
  try{
    return this.httpClient.post(`${this.baseUrl}/api/admin/adminDashboard/removefromreject`,{'user_id':user_id,'appl_id':appl_id});
  }catch(error) {
      this.handleError("resendAppToPending : "+JSON.stringify(error));
  }
}
mergedpdf(appl_id) {
  try{
   return this.httpClient.post(`${this.baseUrl}/api/signpdf/mergedpdf`,{"appl_id":appl_id})
  }catch(error) {
    this.handleError("getEmailActivityTracker : "+JSON.stringify(error));
  }
}



// sendemail(appl_id) {
//   try{
//    return this.httpClient.post(`${this.baseUrl}/api/signpdf/email_signpdf`,{"appl_id":appl_id})
//   }catch(error) {
//     this.handleError("getEmailActivityTracker : "+JSON.stringify(error));
//   }
// }

sendemail(appl_id , type) {
  try{
   return this.httpClient.post(`${this.baseUrl}/api/signpdf/documentSending`,{"appl_id":appl_id , "type" : type})
  }catch(error) {
    this.handleError("getEmailActivityTracker : "+JSON.stringify(error));
  }
}

uploadWes(user_id,dateOfWes){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/wes/uploadWes`,{"user_id":user_id,"date":dateOfWes})
   }catch(error) {
     this.handleError("uploadWes : "+JSON.stringify(error));
   } 
}


private handleError1(error: HttpErrorResponse):
  Observable<any> {
    //Log error in the browser console
    console.error('observable error: ', error);

    return Observable.throw(error);
  }

//NEW END

  private handleError(error){
    console.error('errorerrorerrorerrorerror' ,error);
  }

  updateLoginCount(user_id){
    try{
      return this.httpClient.put(`${this.baseUrl}/api/attestation/updateLoginCount`,{"user_id": user_id});
    }catch(error) {
      this.handleError("updateLoginCount : "+JSON.stringify(error));
    }
  }

  refreshCollegeEmailStatus(user_id,college_id){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/admin/collegeEmailStatusUpdate?user_id=${user_id}&college_id=${college_id}`);
    }catch(error) {
        this.handleError("refreshCollegeEmailStatus : "+JSON.stringify(error));
    }
  }

  sendEmailToCollege(collegeId,user_id,app_id){
    try{
      return this.httpClient.post(`${this.baseUrl}/api/admin/sendEmailToCollege`,{"collegeId":collegeId,"user_id":user_id,"app_id": app_id});
    }catch(error) {
      this.handleError("sendEmailToCollege : "+error);
    }
  }

  getInstructionalEducation(editFlag,app_id,degreeValue){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/attestation/getInstructionalDetails?editFlag=${editFlag}&app_id=${app_id}&degreeValue=${degreeValue}`);
    }catch(error) {
      this.handleError("getInstructionalEducation: "+JSON.stringify(error));
    }
  }

  getAffiliationLetterDetails(editFlag,app_id,degreeValue){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/attestation/getAffiliationLetterDetails?editFlag=${editFlag}&app_id=${app_id}&degreeValue=${degreeValue}`);
    }catch(error) {
      this.handleError("getAffiliationLetterDetails: "+JSON.stringify(error));
    }
  }
  
  addUserInstructionalDetails(instructionalDetails:boolean){ 
    try{
      return this.httpClient.post(`${this.baseUrl}/api/attestation/instructionalDetails`,{"instructionalDetails" :instructionalDetails});
    }catch(error) {
      this.handleError("addUserInstructionalDetails: "+JSON.stringify(error));
    }
  }

  addAffiliationDetails(object:Object){ 
    try{
      return this.httpClient.post(`${this.baseUrl}/api/attestation/affiliationUserDetails`,object);
    }catch(error) {
      this.handleError("addAffiliationDetails: "+JSON.stringify(error));
    }
  }
  
  addUserCurriculumDetails(curriculumDetails:boolean){ 
    try{
      return this.httpClient.post(`${this.baseUrl}/api/attestation/curriculumDetails`,{"curriculumDetails" :curriculumDetails});
    }catch(error) {
      this.handleError("addUserInstutional: "+JSON.stringify(error));
    }
  }
  
  addUserEducationalDetails(educationalDetails:boolean,value){ 
    try{
      return this.httpClient.post(`${this.baseUrl}/api/attestation/educationalDetails`,{"educationalDetailsValues" :educationalDetails, "applied_for" : value});
    }catch(error) {
      this.handleError("addUserInstutional: "+JSON.stringify(error));
    }
  }
  
  
  
  addinstructionalDetails(object:Object){ 
    try{
      return this.httpClient.post(`${this.baseUrl}/api/attestation/instructionalUserDetails`,object);
    }catch(error) {
      this.handleError("addUserInstutional: "+JSON.stringify(error));
    }
  }
  
  
  
  downloadCurriculum(file_name):Observable<Blob>{
    try{
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.httpClient.get(`${this.baseUrl}/api/dashboard/downloadCurriculum?file_name=`+file_name, { headers: headers, responseType: 'blob'}).map(
        (res) => {
            return new Blob([res], { type: 'application/pdf' });
        });
    }catch(error) {
      this.handleError("unable to get Files : "+JSON.stringify(error));
    }
  }

  downloadMarklist(file_name):Observable<Blob>{
    try{
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.httpClient.get(`${this.baseUrl}/api/attestation/downloadMarklist?file_name=`+file_name, { headers: headers, responseType: 'blob'}).map(
        (res) => {
            return new Blob([res], { type: 'application/pdf' });
        });
    }catch(error) {
      this.handleError("unable to get Files : "+JSON.stringify(error));
    }
  }
  
  
  saveNameChangedata(data){ 
    try{
      return this.httpClient.post(`${this.baseUrl}/api/attestation/saveLetterNameChangeData`,data);
    }catch(error) {
      this.handleError("addUserInstutional: "+JSON.stringify(error));
    }
  }
  deleteUploadpdf(id){
    try{      
        return this.httpClient.delete(`${this.baseUrl}/api/attestation/deleteUploadpdf?id=`+id);
  
    }catch(error) {
      this.handleError("deleteUploadpdf: "+JSON.stringify(error));
    }
  }
  
  getAdminInstructionalEducation(userId,app_id){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/admin/getAdminInstructionalDetails?userId=${userId}&app_id=${app_id}`);
    }catch(error) {
      this.handleError("getAdminInstructionalEducation: "+JSON.stringify(error));
    }
  }

    
  getWesFormData(userId,app_id){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/admin/getWesFormData?userId=${userId}&app_id=${app_id}`);
    }catch(error) {
      this.handleError("getWesFormData: "+JSON.stringify(error));
    }
  }


  getAdminLetterForNameChange(userId,appId){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/admin/getAdminLetterForNameChange?userId=${userId}&appId=${appId}`);
    }catch(error) {
      this.handleError("getAdminLetterForNameChange: "+JSON.stringify(error));
    }
  }


  getAdminHrdEducation(userId){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/attestation/getAdminHrdDetails?userId=`+userId);
    }catch(error) {
      this.handleError("getAdminHrdEducation: "+JSON.stringify(error));
    }
  }

  getApplied_for(userId){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/attestation/getApplied_for?userId=`+userId);
    }catch(error) {
      this.handleError("getApplied_for: "+JSON.stringify(error));
    }
  }

  // wesDate(date,user_id){
  //   try{
  //     return this.httpClient.post(`${this.baseUrl}/api/wes/wesDate`,{"date":date,"user_id":user_id})
  //    }catch(error) {
  //      this.handleError("wesDate : "+JSON.stringify(error));
  //    } 
  // }
  
  
  wesStudent(page,id,name,email,wesno,year){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/wes/getAllWes?page=${page}&id=${id}&name=${name}&email=${email}&wesno=${wesno}&acadYear=${year}`);

    }catch(error){
      this.handleError("wesDate : "+JSON.stringify(error));
    }
    }

    applicationsCount(type){
      try{
        return this.httpClient.get(`${this.baseUrl}/api/wes/applicationsCount?type=${type}`);
  
      }catch(error){
        this.handleError("wesDate : "+JSON.stringify(error));
      }
      }
  
  


  
    getuploadedCurriculum(app_id){
      try{
        return this.httpClient.get(`${this.baseUrl}/api/dashboard/getuploadedCurriculum?app_id=${app_id}`,);
      }catch(error) {
          this.handleError("getuploadedCurriculum : "+JSON.stringify(error));
      }
    }
viewFileStatus(appId,userId){
  try{
  const requestUrl = `${this.baseUrl}/api/wes/viewFileStatus?app_id=${appId}&userid=${userId}`;
  return this.httpClient.get(requestUrl);
}catch(error){
  this.handleError("getWesFiles : "+JSON.stringify(error));
}
}

getWesInvalidUser(){
  try{
  const requestUrl = `${this.baseUrl}/api/wes/getInvalidUser`;
  return this.httpClient.get(requestUrl);
}catch(error){
  this.handleError("getWesInvalidUsers: "+JSON.stringify(error));
}
}

  viewPdf(appId,userId,fileName){
    try{
    const requestUrl = `${this.baseUrl}/api/wes/viewPdf?app_id=${appId}&userid=${userId}&filename=${fileName}`;
    return this.httpClient.get(requestUrl);
  }catch(error){
    this.handleError("getWesApplications : "+JSON.stringify(error));
  }
  }

  generateInstrucionalLetter(app_id){ 
    try{
      return this.httpClient.post(`${this.baseUrl}/api/admin/generateInstrucionalLetter`,{"app_id" : app_id});
    }catch(error) {
      this.handleError("generateInstrucionalLetter: "+JSON.stringify(error));
    }
  }
  saveAffiliationInstructional(userId,value,app_id,data){    
    try{
      return this.httpClient.post(`${this.baseUrl}/api/admin/saveAffiliationInstructional`,{"userId" : userId , "value" : value , "app_id" : app_id , "data" :  data});
    }catch(error) {
      this.handleError("saveAffiliationInstructional: "+JSON.stringify(error));
    }
  }

  editHrdDetails(value,userId){ 
    try{
      return this.httpClient.post(`${this.baseUrl}/api/admin/editHrdDetails`,{"value" : value , "userId" : userId});
    }catch(error) {
      this.handleError("editHrdDetails: "+JSON.stringify(error));
    }
  }
  editAffiliationandInstructionalDetails(value,userId,form){     
    try{
      return this.httpClient.post(`${this.baseUrl}/api/admin/editAffiliationandInstructionalDetails`,{"value" : value , "userId" : userId , "form" : form});
    }catch(error) {
      this.handleError("editAffiliationandInstructionalDetails: "+JSON.stringify(error));
    }
  }



  removespace(userId){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/attestation/getname?userId=${userId}`);
    }catch(error) {
      this.handleError("removespace: "+JSON.stringify(error));
    }
  }
  editLetterForNameChange(value,userId){     
    try{
      return this.httpClient.post(`${this.baseUrl}/api/admin/editLetterForNameChange`,{"value" : value , "userId" : userId});
    }catch(error) {
      this.handleError("editLetterForNameChange: "+JSON.stringify(error));
    }
  }

  getMarkListDetails(degreeName,editFlag,app_id){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/attestation/getMarkListDetails?degreeName=${degreeName}&editFlag=${editFlag}&app_id=${app_id}`);
    }catch(error) {
      this.handleError("getMarkListDetails: "+JSON.stringify(error));
    }
  }

  saveMarkListDetails(data){
    try{
      return this.httpClient.post(`${this.baseUrl}/api/attestation/saveMarkListDetails`,{"data":data})
     }catch(error) {
       this.handleError("saveMarkListDetails : "+JSON.stringify(error));
     }
  }
  saveHrdAdminDetails(userId ,type){
    try{
      return this.httpClient.post(`${this.baseUrl}/api/attestation/saveHrdAdminDetails`,{"userId":userId ,"type" :type })
     }catch(error) {
       this.handleError("saveHrdAdminDetails : "+JSON.stringify(error));
     }
  }

// Admin side email sent to student
  sendEmailToStudent(value,email,appId){
    try{
      return this.httpClient.post(`${this.baseUrl}/api/attestation/sendEmailToStudent`,{"value":value ,"email" :email , "appId" : appId})
     }catch(error) {
       this.handleError("sendEmailToStudent : "+JSON.stringify(error));
     }
  }

  deleteDetails(id){
    try{
      return this.httpClient.post(`${this.baseUrl}/api/attestation/deleteDetails`,{'id':id});
    }catch(error) {
      this.handleError("getUploadUserMarklist: "+JSON.stringify(error));
    }
  }
  

  getCollegeData(){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/admin/collegeManagement/getCollegeData`);
    }catch(error) {
        this.handleError("getCollegeData : "+JSON.stringify(error));
      }
  }

  getFieldStudyData(){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/admin/collegeManagement/getFieldStudyData`);
    }catch(error) {
        this.handleError("getFieldStudyData : "+JSON.stringify(error));
      }
  }

  rejectApplication(user_id,app_id,fromTab,message){    
    try{
      return this.httpClient.post(`${this.baseUrl}/api/admin/adminDashboard/rejectApplication`,{"user_id":user_id,"app_id":app_id, "fromTab" : fromTab,"message":message});;
        //return this.httpClient.get(requestUrl);
      }catch(error){
        this.handleError("rejectApplication : "+JSON.stringify(error));
      }
  }

  getRejectedApplication(){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/getRejectedApplication`);
        //return this.httpClient.get(requestUrl);
      }catch(error){
        this.handleError("getRejectedApplication : "+JSON.stringify(error));
      }
  }

  addOrUpdateCollegeData(data){
    try{
      return this.httpClient.post(`${this.baseUrl}/api/admin/collegeManagement/addOrUpdateCollegeData`,{"collegeData":data})
     }catch(error) {
       this.handleError("addOrUpdateCollegeData : "+JSON.stringify(error));
     }
  }

  addOrUpdateFacultyData(data){
    try{
      return this.httpClient.post(`${this.baseUrl}/api/admin/collegeManagement/addOrUpdateFacultyStudyData`,{"FieldStudyData":data})
     }catch(error) {
       this.handleError("addOrUpdateFacultyData : "+JSON.stringify(error));
     }
  }
  
  removeCollege(college_id){
    try{
      return this.httpClient.post(`${this.baseUrl}/api/admin/collegeManagement/removeCollege`,{"college_id":college_id})
     }catch(error) {
       this.handleError("removeCollege : "+JSON.stringify(error));
     }
  }

  removeFaculty(faculty_id){
    try{
      return this.httpClient.post(`${this.baseUrl}/api/admin/collegeManagement/removeFacultyStudyData`,{"faculty_id":faculty_id})
     }catch(error) {
       this.handleError("removeFaculty : "+JSON.stringify(error));
     }
  }

  sendEmailCollege(college_id){
    try{
      return this.httpClient.post(`${this.baseUrl}/api/admin/collegeManagement/sendEmailCollege`,{"college_id":college_id})
     }catch(error) {
       this.handleError("sendEmailCollege : "+JSON.stringify(error));
     }
  }

  refreshPurposeEmailStatus(){
    try{
      return  this.httpClient.get(`${this.baseUrl}/api/cron/purposeEmailUpdate`);     
    }catch(error){
      this.handleError('refreshPurposeEmailStatus: ' + JSON.stringify(error));
    }  
  }
  
  getErrataDocs(){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/errataDocuments`);
    }catch(error) {
        this.handleError("sendOtp : "+JSON.stringify(error));
    }
  }

  lockDocument(doc_id,user_id,set,app_id){
    try{
      return this.httpClient.post(`${this.baseUrl}/api/admin/adminDashboard/lockDocuments`,{doc_name:name,doc_id:doc_id,user_id:user_id,set:set, app_id : app_id});
    }catch(error) {
        this.handleError("sendOtp : "+JSON.stringify(error));
    }
  } 

  downloadDocument(file_name):Observable<Blob>{
    try{
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.httpClient.get(`${this.baseUrl}/api/admin/downloadDocument?file_name=`+file_name, { headers: headers, responseType: 'blob'}).map(
        (res) => {
            return new Blob([res], { type: 'application/pdf' });
        });
    }catch(error) {
      this.handleError("unable to get Files : "+JSON.stringify(error));
    }
  }
 
  preview(){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/attestation/preview`);
    }catch(error) {
        this.handleError("sendOtp : "+JSON.stringify(error));
    }
  }

  addExtraPurposeEmail(user_id,app_id,emailArray){
    try{
      return this.httpClient.post(`${this.baseUrl}/api/admin/addExtraPurposeEmail`,{user_id:user_id, app_id : app_id, emailArray: emailArray});
    }catch(error) {
        this.handleError("sendOtp : "+JSON.stringify(error));
    }
  }

  changeDocumentName(id,collegeId,type,usermarklist){
    try{
      return this.httpClient.post(`${this.baseUrl}/api/admin/changeDocumentName`,{id:id, collegeId : collegeId, type: type,usermarklist:  usermarklist});
    }catch(error) {
        this.handleError("changeDocumentName : "+JSON.stringify(error));
    }
  }

  saveNotes(id,notes,type){    
    try{
      return this.httpClient.post(`${this.baseUrl}/api/admin/saveNotes`,{id : id, notes : notes,type : type});
    }catch(error) {
        this.handleError("saveNotes : "+JSON.stringify(error));
    }
  }

  pendingApplicationList(college_id,college_name){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/admin/collegeManagement/pendingApplicationList?college_id=${college_id}&college_name=${college_name}`)
     }catch(error) {
       this.handleError("pendingApplicationList : "+JSON.stringify(error));
     }
  }

  replyFromCollege(app_id,notes,college_id,result){
    try{
      return this.httpClient.post(`${this.baseUrl}/api/replyFromCollege`,{app_id : app_id, notes : notes, college_id: college_id, result: result});
    }catch(error) {
        this.handleError("replyFromCollege : "+JSON.stringify(error));
    }
  }

  transcriptRequiredMail(app_id,user_id,event,type){
    try{
      return this.httpClient.post(`${this.baseUrl}/api/admin/transcriptRequiredMail`,{app_id : app_id, user_id : user_id, eventChecked : event, type : type});
    }catch(error) {
        this.handleError("transcriptRequiredMail : "+JSON.stringify(error));
    }
  }

  convocationDecision(app_id,user_id,event,type,name){	
    try{	
      return this.httpClient.post(`${this.baseUrl}/api/admin/convocationDecision`,{app_id : app_id, user_id : user_id, eventChecked : event,type : type, name});	
    }catch(error) {	
        this.handleError("convocationDecision : "+JSON.stringify(error));	
    }	
  }	
  marksheetsRequired(app_id,user_id,event,type){	
    
    
    try{	
      return this.httpClient.post(`${this.baseUrl}/api/admin/marksheetsRequired`,{app_id : app_id, user_id : user_id, eventChecked : event,type : type});	
    }catch(error) {	
        this.handleError("marksheetsRequired : "+JSON.stringify(error));	
    }	
  }	
  SetAppId(app_id,user_id,type){	
    try{	
      return this.httpClient.post(`${this.baseUrl}/api/admin/SetAppId`,{app_id : app_id, user_id : user_id,type : type});	
    }catch(error) {	
        this.handleError("SetAppId : "+JSON.stringify(error));	
    }	
  }	
  updateNoteAndApplication(app_id,user_id,event,type,note){	
    try{	
      return this.httpClient.post(`${this.baseUrl}/api/admin/updateNoteAndApplication`,{app_id : app_id, user_id : user_id, eventChecked : event,type : type,note : note});	
    }catch(error) {	
        this.handleError("updateNoteAndApplication : "+JSON.stringify(error));	
    }	
  }
  

  setCollegeConfirmation(app_id,event){
    try{
      return this.httpClient.post(`${this.baseUrl}/api/admin/setCollegeConfirmation`,{app_id : app_id, eventChecked : event});
    }catch(error) {
        this.handleError("setCollegeConfirmation : "+JSON.stringify(error));
    }
  }

  async getAllTranscriptDataAppWise(userId, Appc_id){
    try{
      return await this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/appWiseDocs?userId=`+userId+`&app_id=`+Appc_id).toPromise();
    }catch(error) {
      this.handleError("Error in getAllTranscriptDataAppWise : "+error);
    }
  }

  getPurposeWiseApplicationsCount(){
    try{
      return  this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/getPurposeWiseApplicationsCount`);
    }catch(error) {
      this.handleError("Error in getPurposeWiseApplicationsCount : "+error);
    }
  }

  getCollegeWiseApplicationsCount(page){
    try{
      return  this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/getCollegeWiseApplicationsCount?page=${page}`);
    }catch(error) {
      this.handleError("Error in getCollegeWiseApplicationsCount : "+error);
    }
  }


  getNameChangeData(){
    try{
      return  this.httpClient.get(`${this.baseUrl}/api/attestation/getNameChangeData`);
    }catch(error) {
      this.handleError("Error in getNameChangeData : "+error);
    }
  }

  deleteErrataLetter(userData){
    try{
      return this.httpClient.post(`${this.baseUrl}/api/attestation/deleteErrataLetter`,{'data':userData});
    }catch(error) {
      this.handleError("deleteErrataLetter: "+JSON.stringify(error));
    }
  }

  addUsergradToPerDetails(gradToPer:boolean){ 
    try{
      return this.httpClient.post(`${this.baseUrl}/api/attestation/addUsergradToPerDetails`,{"gradToPer" :gradToPer});
    }catch(error) {
      this.handleError("addUsergradToPerDetails: "+JSON.stringify(error));
    }
  }

  getGradeToPercentageLetterDetails(editFlag,app_id){
    try{
      return  this.httpClient.get(`${this.baseUrl}/api/attestation/getGradeToPercentageLetterDetails?editFlag=${editFlag}&app_id=${app_id}`);
    }catch(error) {
      this.handleError("Error in getGradeToPercentageLetterDetails : "+error);
    }
  }

  getExtraDocuments(){
    try{
      return  this.httpClient.get(`${this.baseUrl}/api/attestation/getExtraDocuments`);
    }catch(error) {
      this.handleError("Error in getExtraDocuments : "+error);
    }
  }

  deleteDocument(id,type){
    try{      
        return this.httpClient.delete(`${this.baseUrl}/api/attestation/deleteDocument?id=${id}&type=${type}`);
            
    }catch(error) {
      this.handleError("deleteDocument: "+JSON.stringify(error));
    }
  }

  getCountofApplications(date){
    try{
      return  this.httpClient.get(`${this.baseUrl}/api/admin/getCountofApplications?date=${date}`);
    }catch(error) {
      this.handleError("Error in getCountofApplications : "+error);
    }
  }

  getUploadedMarksheetDetails(eduDetails){
    try{
      return  this.httpClient.get(`${this.baseUrl}/api/attestation/getUploadedMarksheetDetails?eduDetails=${eduDetails}`);
    }catch(error) {
      this.handleError("Error in getUploadedMarksheetDetails : "+error);
    }
  }

  getNamechangeLetter(){
    try{
      return  this.httpClient.get(`${this.baseUrl}/api/attestation/getNamechangeLetter`);
    }catch(error) {
      this.handleError("Error in getNamechangeLetter : "+error);
    }
  }
  setDiplomaOrCurrent(type,value){
    try{
      return  this.httpClient.post(`${this.baseUrl}/api/attestation/setDiplomaOrCurrent`,{type: type, value : value});
    }catch(error) {
      this.handleError("Error in setDiplomaOrCurrent : "+error);
    }
  }

  setCollegeConfirmations(id,collegeName,event){
    try{
      return  this.httpClient.post(`${this.baseUrl}/api/admin/setCollegeConfirmations`,{id: id, collegeName : collegeName , event : event});
    }catch(error) {
      this.handleError("Error in setCollegeConfirmations : "+error);
    }
  }

  setCandidateRemark(event,id,email){
    try{
      return  this.httpClient.post(`${this.baseUrl}/api/admin/setCandidateRemark`,{event : event , id: id , email :email});
    }catch(error) {
      this.handleError("Error in setCandidateRemark : "+error);
    }
  }

  oldDocuments(app_id,user_id,checked){
    try{
      return  this.httpClient.post(`${this.baseUrl}/api/admin/oldDocuments`,{app_id : app_id, id: user_id, eventChecked : checked});
    }catch(error) {
      this.handleError("Error in oldDocuments : "+error);
    }
  }

  resetErrorApplication(app_id){
    try{
      return  this.httpClient.post(`${this.baseUrl}/api/admin/resetErrorApplication`,{app_id : app_id});
    }catch(error) {
      this.handleError("Error in oldDocuments : "+error);
    }
  }
  
  admin_pass_reset(user_mail){
    
    try{
      
      return this.httpClient.post(`${this.baseUrl}/api/admin/adminResetPassword`,{data: user_mail});
    }catch (error) {
      this.handleError("adminResetPassword: "+JSON.stringify(error));
    }
  }

  admin_document_reset(userID){
    
    try {
      
      return this.httpClient.delete(`${this.baseUrl}/api/admin/adminResetDoc?userID=${userID}`);
    } catch (error) {
      this.handleError("adminResetDoc: "+JSON.stringify(error));
    }
  }

  deleteTotalMarklist(userData, types){
    
    try{
      return this.httpClient.post(`${this.baseUrl}/api/admin/deleteMarksheet`,{'data':userData, types});
    }catch(error) {
      this.handleError("deleteMarksheet: "+JSON.stringify(error));
    }
  }
  name_changes(FirstName, LastName, userID){
    
    try {
      return this.httpClient.post(`${this.baseUrl}/api/admin/nameChange`,{
        data: userID, 
        fname: FirstName, 
        lname: LastName
      });
    } catch (error) {
      this.handleError("nameChange: "+JSON.stringify(error));
    }
  }

  verifyOtp(otp, email, userId){
    try{
      return this.httpClient.put(`${this.baseUrl}/api/admin/adminDashboard/verifyOtp`,{"otp": otp, "email": email, "userId": userId});
    }catch(error) {
      this.handleError("verifyOTPError : "+JSON.stringify(error));
    }
  }

  
  getAffiliationAdmindetails(userId,app_id){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/admin/getAffiliationAdmindetails?userId=${userId}&app_id=${app_id}`);
    }catch(error) {
      this.handleError("getAffiliationAdmindetails: "+JSON.stringify(error));
    }
  }

  editinstructionalDetails(object:Object){ 
    try{
      return this.httpClient.post(`${this.baseUrl}/api/attestation/editinstructionalUserDetails`,object);
    }catch(error) {
      this.handleError("editinstructionalUserDetails: "+JSON.stringify(error));
    }
  }

  editaffiliationLetterFormValue(object:Object){ 
    try{
      return this.httpClient.post(`${this.baseUrl}/api/attestation/editaffiliationLetterFormValue`,object);
    }catch(error) {
      this.handleError("editinstructionalUserDetails: "+JSON.stringify(error));
    }
  }
  deleteInfo(education,type,id){
    try{      
        return this.httpClient.delete(`${this.baseUrl}/api/attestation/deleteInfo?education=${education}&type=${type}&id=${id}`);
            
    }catch(error) {
      this.handleError("deleteInfo: "+JSON.stringify(error));
    }
  }

  mergeFile(app_id,user_id){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/signpdf/merge_documents?app_id=${app_id}&user_id=${user_id}`);
    }catch(error) {
        this.handleError("getTranscriptDetails : "+JSON.stringify(error));
    }
  }


  documentMerge(app_id,user_id){
    try{
      return  this.httpClient.post(`${this.baseUrl}/api/signpdf/documentMerge`,{user_id : user_id, app_id : app_id});
    }catch(error) {
      this.handleError("documentMerge : "+error);
    }
  }

  getStudentEducationData(user_id,app_id){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/admin/getStudentEducationData?app_id=${app_id}&user_id=${user_id}`);
    }catch(error) {
        this.handleError("getStudentEducationData : "+JSON.stringify(error));
    }
  }
  getUploadedCompentencyletter(degreeValue, editFlag, app_id){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/dashboard/getUploadedCompentencyletter?degreeValue=${degreeValue}&editFlag=${editFlag}&app_id=${app_id}`,);
    }catch(error) {
        this.handleError("getUploadedCompentencyletter : "+JSON.stringify(error));
    }
  }
  
  purposeWiseApplications(event){    
    try{
      return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/purposeWise_signed?event=${event}`);
    }catch(error) {
        this.handleError("purposeWiseApplications : "+JSON.stringify(error));
    }
  }
  purposeWiseApplications_verified(event){    
    try{
      return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/purposeWise_verified?event=${event}`);
    }catch(error) {
        this.handleError("purposeWiseApplications_verified : "+JSON.stringify(error));
    }
  }
  getCompetencyLetterDetails(editFlag,app_id){
    try{
      return  this.httpClient.get(`${this.baseUrl}/api/attestation/getCompetencyLetterDetails?editFlag=${editFlag}&app_id=${app_id}`);
    }catch(error) {
      this.handleError("Error in getCompentencyLetterDetails : "+error);
    }
  }


  setCurrentLocation(location,user_id){
    try{
      return  this.httpClient.post(`${this.baseUrl}/api/admin/setCurrentLocation`,{user_id : user_id, location : location});
    }catch(error) {
      this.handleError("Error in setCurrentLocation : "+error);
    }
  }

  download_Manual(file_name):Observable<Blob>{
    try{
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.httpClient.get(`${this.baseUrl}/api/admin/downloadManualAttestation?file_name=`+file_name, { headers: headers, responseType: 'blob'}).map(
        (res) => {
            return new Blob([res], { type: 'application/pdf' });
        });
    }catch(error) {
      this.handleError("unable to get Files : "+JSON.stringify(error));
    }
  }

  changeCollegeDocumentName(id,changedName,type){
    try{
      return this.httpClient.post(`${this.baseUrl}/api/admin/changeCollegeDocumentName`,{id:id, changedName : changedName, type: type});
    }catch(error) {
        this.handleError("changeDocumentName : "+JSON.stringify(error));
    }
  }
  downloadExcel_college_confirm(){
    try{
      return  this.httpClient.get(`${this.baseUrl}/api/admin/downloadExcel_college_confirm`);
    }catch(error) {
      this.handleError("Error in downloadExcel_college_confirm : "+error);
    }
  }

  
getVerifiedOtp(userId:number):any{
  try{
      return this.httpClient.get(`${this.baseUrl}/api/attestation/verifiedOtp?userId=${userId}`);
     }catch(error) {
        this.handleError("updateCourseName : "+JSON.stringify(error));
     }
  }
  
  postVerifiedOtp(userId:number,value:boolean) {
  try{
        return this.httpClient.post(`${this.baseUrl}/api/attestation/postVerifiedOtp`,{"userId":userId,"value":value});
     }catch(error) 
     {
        this.handleError("updateCourseName : "+JSON.stringify(error));
     }
  }

  orderLookUp(orderId){
    try{
      return this.httpClient.get(`${this.baseUrl}/api/payment/orderLookupSingle?orderId=${orderId}`);
    }catch(error) {
      this.handleError("Error in orderLookupSingle : "+error);
    }
  }

  /*delete the payment Issue data when orderId is null */
deletePaymentIssueData(id){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/attestation/deletePaymentIssueData`,{'id':id});
  }catch(error) {
    this.handleError("deletePaymentIssueData: "+JSON.stringify(error));
  }

}

getFileName(id){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/getFileName?id=${id}`);     
  }catch(error){
    this.handleError('getFileName: ' + JSON.stringify(error));
  }
}

/*Retrieve the information based on their current status.*/
getPayIssueDetail(page,tracker,email){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/getPayIssueDetail?page=${page}&tracker=${tracker}&email=${email}`);
  }catch(error) {
  
    this.handleError("getPayIssueDetail : "+JSON.stringify(error));
  }
}

 /*Storing notes added by an administrator.*/
 saveNote(note,id,tracker,user_id){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/attestation/saveNotePaymenterror`,{"note":note, "id":id,"tracker":tracker,"user_id":user_id})
   }catch(error) {
     this.handleError("saveNote : "+JSON.stringify(error));
   }
}



getAppliedDetails(appId,userId){
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/getAppliedDetails?userId=${userId}&appId=${appId}`);
  }catch(error) {
    this.handleError("getAppliedDetails: "+JSON.stringify(error));
  }
}

documentsScanOcr(appId,userId){  
  try{
    return this.httpClient.post(`${this.baseUrl}/api/attestation/documentsScan`,{"appId":appId, "userId":userId})
   }catch(error) {
     this.handleError("saveNote : "+JSON.stringify(error));
     
   }
}

documentsScanOcrConvocataion(appId,userId){  
  try{
    return this.httpClient.post(`${this.baseUrl}/api/attestation/documentsScanConvocataion`,{"appId":appId, "userId":userId})
   }catch(error) {
     this.handleError("saveNote : "+JSON.stringify(error));
   }
}

documentsScanOcrTrancript(appId,userId){  
  try{
    return this.httpClient.post(`${this.baseUrl}/api/attestation/documentsScanTrancript`,{"appId":appId, "userId":userId})
   }catch(error) {
     this.handleError("saveNote : "+JSON.stringify(error));
   }
}

updatedAppliedDetails(applied,userId,appId){
  try {
    return this.httpClient.post(`${this.baseUrl}/api/admin/adminDashboard/updatedAppliedDetails`,{"applied" : applied ,"userId":userId,"appId":appId})
  } catch (error) {
    this.handleError("updatedAppliedDetails"+JSON.stringify(error))
  }
}

removeDegree(degree,appId){
  try {
    return this.httpClient.post(`${this.baseUrl}/api/admin/adminDashboard/removeDegree`,{"degree" : degree ,"appId":appId})
  } catch (error) {
    this.handleError("removeDegree"+JSON.stringify(error))
  }
}

download_applicationForm(file_name,user_id,app_id):Observable<Blob>{
  try{
  let headers = new HttpHeaders();
  headers.append('Content-Type', 'application/json');
  return this.httpClient.get(`${this.baseUrl}/api/payment/download_signedDocuments?file_name=${file_name}&user_id=${user_id}&app_id=${app_id}`, { headers: headers, responseType: 'blob'}).map(
      (res) => {
          return new Blob([res], { type: 'application/pdf' });
      });
  }catch(error) {
    this.handleError("unable to get Files : "+JSON.stringify(error));
  }
}


delete(id,type){
  try {
    return this.httpClient.delete(`${this.baseUrl}/api/admin/adminDashboard/delete?id=${id}&type=${type}`)
  } catch (error) {
    this.handleError("Error in deleteNamechangeLetter : "+error);

  }
}


// saveNameChangeLetter(data){ 
//   try{
//     return this.httpClient.post(`${this.baseUrl}/api/admin/adminDashboard/saveNameChangeLetter`,data);
//   }catch(error) {
//     this.handleError("saveNameChangeLetter: "+JSON.stringify(error));
//   }
// }
candidateRemarksType(value,appId){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/attestation/candidateRemarksType`,{"value":value ,"appId":appId})
   }catch(error) {
     this.handleError("candidateRemarksType : "+JSON.stringify(error));
   }
}
candidateRemarksRemove(appId){
  try {
    return this.httpClient.delete(`${this.baseUrl}/api/admin/adminDashboard/candidateRemarksRemove?appId=${appId}`)
  } catch (error) {
    this.handleError("candidateRemarksRemove : "+JSON.stringify(error));

  }
}

getDataById(id,type){
  try{
    return  this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/getDataById?id=${id}&type=${type}`);
  }catch(error) {
    this.handleError("Error in getDataById : "+error);
  }
}

saveForm(data,type){ 
  try{
    return this.httpClient.post(`${this.baseUrl}/api/admin/adminDashboard/saveForm`,{"data" : data ,"type":type});
  }catch(error) {
    this.handleError("saveForm: "+JSON.stringify(error));
  }
}

updateErrataTranscript(msg_from_admin_data,errataData , app_id){
  try{
    return this.httpClient.post(`${this.baseUrl}/api/admin/adminDashboard/updateErrataTranscript`,{"msg":msg_from_admin_data,"errataData" : errataData ,"app_id" : app_id});
  }catch(error) {
    this.handleError("updateErrataTranscript : "+error);
  }
}

getErrorMsg(value){      
  try{
    return this.httpClient.get(`${this.baseUrl}/api/attestation/showErrorMessage?value=`+value);
  }catch(error) {
    this.handleError("getErrorMsg : "+JSON.stringify(error));
  }
}

getWesDetails(wesNo,email,surName,name){      
  try{
    return this.httpClient.get(`${this.baseUrl}/api/admin/adminDashboard/getWesDetails?wesNo=${wesNo}&email=${email}&surName=${surName}&name=${name}`);
  }catch(error) {
    this.handleError("getErrorMsg : "+JSON.stringify(error));
  }
}

removeErrata(type,app_id,user_id){	
    
    
  try{	
    return this.httpClient.post(`${this.baseUrl}/api/admin/removeErrata`,{type : type,app_id : app_id, user_id : user_id});	
  }catch(error) {	
      this.handleError("removeErrata : "+JSON.stringify(error));	
  }	
}
}
